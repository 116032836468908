import "reflect-metadata";
import {
    Constants,
    IClassifierFilter,
    pathof,
    PetTypes,
    ReferenceTable,
    ReferenceTableValue,
    TFilterValue
} from "@folksam-digital/model";
import {injectable} from "inversify";
import {IApplicationParamService} from "../../IApplicationParamService";
import {IClassifier, IClassifierFilterExclude, IClassifierService, IQueryParams} from "../../IClassifierService";
import {IStorage} from "../../IStorage";
import {Endpoint, IWorkflowServiceConfig, WorkflowServiceBase} from "./WorkflowServiceBase";
import {Workflow} from "./Workflow";
import {ISessionContextManager} from "../../ISessionContextManager";
import HomeStudentJourneyModel from "@folksam-digital/model/lib/model/HomeStudentJourneyModel";

@injectable()
export class ClassifierService extends WorkflowServiceBase implements IClassifierService {
    private static cache: { [key: string]: Promise<IClassifier[]> } = {};
    private readonly applicationParamService: IApplicationParamService;

    constructor(config: IWorkflowServiceConfig, sessionStorage: IStorage, sessionContextManager: ISessionContextManager, applicationParamService: IApplicationParamService) {
        super(config, sessionStorage, sessionContextManager);
        this.applicationParamService = applicationParamService;
    }

    public async getClassifiers(identifier: string | number, locale: string, filter?: IClassifierFilter, classifierFilterOrder?: boolean, exclude?: IClassifierFilterExclude, params?: IQueryParams): Promise<IClassifier[]> {
        let cacheKey = identifier.toString() + locale;

        if (params) {
            const sortedParams = Object.keys(params)
                .sort()
                .reduce((acc, key) => ({
                    ...acc, [key]: params[key]
                }), {});
            cacheKey += JSON.stringify(sortedParams);
        }

        const promiseFailedOrUndefined = await this.isPromiseFailedOrUndefined(ClassifierService.cache[cacheKey]);

        if (!ClassifierService.cache[cacheKey] || promiseFailedOrUndefined) {
            ClassifierService.cache[cacheKey] = this.getClassifiersInternal(identifier, locale, params);
        }

        let classifiers = await ClassifierService.cache[cacheKey];
        if (exclude) {
            classifiers = this.excludeClassifiers(classifiers, exclude);
        }
        if (filter) {
            classifiers = this.filterClassifiers(classifiers, filter, classifierFilterOrder);
        }
        return classifiers;
    }

    private async isPromiseFailedOrUndefined(promise: Promise<IClassifier[]>): Promise<boolean> {
        return Promise.resolve(promise)
            .then(() => {
                return false;
            }).catch(() => {
                return true;
            });
    }

    private excludeClassifiers(classifiers: IClassifier[], exclude: IClassifierFilterExclude): IClassifier[] {
        for (const fieldName in exclude) {
            const values = exclude[fieldName];
            classifiers = classifiers.filter(classifier => {
                // @ts-ignore-next-line
                const fieldValue = classifier[fieldName];
                for (const excludeValue of values) {
                    if (excludeValue.toString() !== fieldValue.toString()) {
                        return true;
                    }
                }
                return false;
            });
        }
        return classifiers;
    }

    private filterClassifiers(classifiers: IClassifier[], filter: IClassifierFilter, classifierFilterOrder?: boolean): IClassifier[] {
        for (const fieldName in filter) {
            const values = filter[fieldName];
            classifiers = classifiers.filter(classifier => {
                // @ts-ignore-next-line
                const fieldValue = classifier[fieldName];
                for (const filterValue of values) {
                    if (filterValue.toString() === fieldValue.toString()) {
                        return true;
                    }
                }
                return false;
            });

            if (classifierFilterOrder && fieldName === "id") {
                return this.sortValuesByClassifierFilterOrder(classifiers, values)
            }
        }

        return classifiers;
    }

    private async getClassifiersInternal(identifier: string | number, locale: string, params?: IQueryParams): Promise<IClassifier[]> {
        if (identifier === Constants.ClassifierIdentifier.NoOfWeeksPregnant) {
            return this.applicationParamService.getApplicationParam(identifier.toString());
        }

        if (identifier === Constants.ClassifierIdentifier.Countries) {
            const values = await this.getReferenceTableValues(identifier, locale);
            return this.sortValuesAlphabetically(values);
        }

        if (identifier === Constants.ClassifierIdentifier.YesNoDontKnow) {
            return Promise.resolve([
                {id: Constants.YesNo.Yes, value: "general.yesNo.yes"},
                {id: Constants.YesNo.DontKnow, value: "general.yesNo.dontKnow"},
                {id: Constants.YesNo.No, value: "general.yesNo.no"},
            ])
        }

        if (identifier === Constants.ClassifierIdentifier.BoatValue) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {id: 1, value: "1000 kr - 2000 kr"},
                {id: 2, value: "2001 kr - 3000 kr"},
                {id: 3, value: "3001 kr - 4000 kr"},
                {id: 4, value: "4001 kr - 5000 kr"},
                {id: 5, value: "5001 kr - 6000 kr"},
                {id: 6, value: "6001 kr - 7000 kr"},
                {id: 7, value: "7001 kr - 8000 kr"},
                {id: 8, value: "8001 kr - 9000 kr"},
                {id: 9, value: "9001 kr - 10 000 kr"},
                {id: 10, value: "10 001 kr - 15 000 kr"},
                {id: 11, value: "15 001 kr - 20 000 kr"},
                {id: 12, value: "20 001 kr - 30 000 kr"},
                {id: 13, value: "30 001 kr - 50 000 kr"},
                {id: 14, value: "50 001 kr - 70 000 kr"},
                {id: 15, value: "70 001 kr - 100 000 kr"},
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.BoatTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {id: Constants.Home.SmallBoatType.Canoe, value: "Kanot"},
                {id: Constants.Home.SmallBoatType.RowingBoat, value: "Roddbåt"},
                {id: Constants.Home.SmallBoatType.PowerBoat, value: "Motorbåt"},
                {id: Constants.Home.SmallBoatType.SailBoatWithMotor, value: "Segelbåt med motor"},
                {id: Constants.Home.SmallBoatType.SailBoatWithOutMotor, value: "Segelbåt utan motor"},
                {id: Constants.Home.SmallBoatType.SailBoard, value: "Segelbräda"}
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.BoatEngineLock) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {id: Constants.Home.SmallBoatEngineLockType.NoEngineLock, value: "Motorlås saknas"},
                {id: Constants.Home.SmallBoatEngineLockType.AbusNemo, value: "Abus Nemo 38"},
                {id: Constants.Home.SmallBoatEngineLockType.AbusOutboardBlock, value: "Abus Outboard Block"},
                {id: Constants.Home.SmallBoatEngineLockType.Duolock, value: "Duolock"},
                {id: Constants.Home.SmallBoatEngineLockType.HillockBultlas, value: "Hillock bultlås"},
                {id: Constants.Home.SmallBoatEngineLockType.HillockMotorlas, value: "Hillock motorlås"},
                {id: Constants.Home.SmallBoatEngineLockType.SafeQuipBultlas, value: "Safe Quip bultlås"},
                {id: Constants.Home.SmallBoatEngineLockType.SafeQuipMotorlas, value: "Safe Quip motorlås"},
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.NumberOfBathrooms) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {id: 1, value: "1"},
                {id: 2, value: "2"},
                {id: 3, value: "3"},
                {id: 4, value: "4"},
                {id: 5, value: "5"},
                {id: 6, value: "6"},
                {id: 7, value: "7"},
                {id: 8, value: "8"},
                {id: 9, value: "9"}
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.IncapacityDegree) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {id: Constants.Incapacity.IncapacityDegree.Quarter, value: "25%"},
                {id: Constants.Incapacity.IncapacityDegree.Half, value: "50%"},
                {id: Constants.Incapacity.IncapacityDegree.ThreeQuarters, value: "75%"},
                {id: Constants.Incapacity.IncapacityDegree.Full, value: "100%"},
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.HomeNumberOfPeople) {
            return Promise.resolve([
                {id: 1, value: "1"},
                {id: 2, value: "2"},
                {id: 3, value: "3"},
                {id: 4, value: "4"},
                {id: 5, value: "5"},
                {id: 6, value: "6"},
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.PhoneTheftTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {id: Constants.MobilePhone.TheftTypes.pickPocketing, value: "claims.mobilePhone.common.theftTypes.pickPocketing"},
                {id: Constants.MobilePhone.TheftTypes.robbery, value: "claims.mobilePhone.common.theftTypes.robbery"},
                {id: Constants.MobilePhone.TheftTypes.burglary, value: "claims.mobilePhone.common.theftTypes.burglary"},
                {id: Constants.MobilePhone.TheftTypes.purseSnatching, value: "claims.mobilePhone.common.theftTypes.purseSnatching"},
                {id: Constants.MobilePhone.TheftTypes.other, value: "claims.mobilePhone.common.theftTypes.other"},
            ]);
        }

        if(identifier === Constants.ClassifierIdentifier.PhoneLossTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {id: Constants.MobilePhone.LossTypes.loss, value: "claims.mobilePhone.common.lossTypes.loss"},
                {id: Constants.MobilePhone.LossTypes.forgotten, value: "claims.mobilePhone.common.lossTypes.forgotten"},
                {id: Constants.MobilePhone.LossTypes.other, value: "claims.mobilePhone.common.lossTypes.other"},
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.InsuranceCompanies) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {id: Constants.InsuranceCompanies.Dina, value: "general.insuranceCompany.dina"},
                {id: Constants.InsuranceCompanies.Gjensidige, value: "general.insuranceCompany.gjensidige"},
                {id: Constants.InsuranceCompanies.ICA, value: "general.insuranceCompany.ica"},
                {id: Constants.InsuranceCompanies.IF, value: "general.insuranceCompany.if"},
                {id: Constants.InsuranceCompanies.Lansforsakringar, value: "general.insuranceCompany.lansforsakringar"},
                {id: Constants.InsuranceCompanies.Moderna, value: "general.insuranceCompany.moderna"},
                {id: Constants.InsuranceCompanies.TreKronor, value: "general.insuranceCompany.treKronor"},
                {id: Constants.InsuranceCompanies.TryggHansa, value: "general.insuranceCompany.tryggHansa"},
                {id: Constants.InsuranceCompanies.Other, value: "general.insuranceCompany.other"}
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.PhoneStatusAtPurchase) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {id: Constants.MobilePhone.StatusAtPurchase.new, value: "claims.mobilePhone.common.statusAtPurchase.new"},
                {id: Constants.MobilePhone.StatusAtPurchase.used, value: "claims.mobilePhone.common.statusAtPurchase.old"}
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.PhonePurchaseTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {id: Constants.MobilePhone.PurchaseTypes.retailer, value: "Retailer"},
                {id: Constants.MobilePhone.PurchaseTypes.private, value: "Private"}
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.PhoneBrands) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {id: Constants.MobilePhone.Brands.apple, value: "Apple phone"},
                {id: Constants.MobilePhone.Brands.samsung, value: "Samsung"},
                {id: Constants.MobilePhone.Brands.huawei, value: "Huawei"},
                {id: Constants.MobilePhone.Brands.onePlus, value: "OnePlus"},
                {id: Constants.MobilePhone.Brands.other, value: "Other"}
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.PhoneModels) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {id: Constants.MobilePhone.Models.iphone8Plus, value: "Iphone 8+"},
                {id: Constants.MobilePhone.Models.iphone8, value: "Iphone 8"},
                {id: Constants.MobilePhone.Models.p30, value: "P30"},
                {id: Constants.MobilePhone.Models.p20, value: "P20"},
                {id: Constants.MobilePhone.Models.galaxyA71, value: "Galaxy 71"},
                {id: Constants.MobilePhone.Models.galaxyA51, value: "Galaxy 51"}
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.PhoneItemAge) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {id: Constants.ClassifierGeneralType.ItemAge.LessThanSixMonth, value: "claims.mobilePhone.common.itemAge.lessThanSixMonth"},
                {id: Constants.ClassifierGeneralType.ItemAge.SixMonthToOneYear, value: "claims.mobilePhone.common.itemAge.sixMonthToOneYear"},
                {id: Constants.ClassifierGeneralType.ItemAge.OneYear, value: "claims.mobilePhone.common.itemAge.oneYear"},
                {id: Constants.ClassifierGeneralType.ItemAge.TwoYears, value: "claims.mobilePhone.common.itemAge.twoYears"},
                {id: Constants.ClassifierGeneralType.ItemAge.ThreeYears, value: "claims.mobilePhone.common.itemAge.threeYears"},
                {id: Constants.ClassifierGeneralType.ItemAge.FourYears, value: "claims.mobilePhone.common.itemAge.fourYears"},
                {id: Constants.ClassifierGeneralType.ItemAge.FiveYearsAndOlder, value: "claims.mobilePhone.common.itemAge.fiveYearsAndOlder"},
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.DelayedLuggageHoursOfDelay) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.DelayedLuggage.HoursOfDelay.LessThanEight,
                    value: "claim.delayedLuggage.hoursOfDelay.lessThan8"
                },
                {
                    id: Constants.DelayedLuggage.HoursOfDelay.EightToTwentyFour,
                    value: "claim.delayedLuggage.hoursOfDelay.from8To24"
                },
                {
                    id: Constants.DelayedLuggage.HoursOfDelay.MoreThanTwentyFour,
                    value: "claim.delayedLuggage.hoursOfDelay.moreThan24"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.DelayedLuggageIncidentLocation) {
            return Promise.resolve([
                {
                    id: Constants.DelayedLuggage.DelayOccurredWhile.WayOut,
                    value: "claim.delayedLuggage.delayType.wayOut"
                },
                {
                    id: Constants.DelayedLuggage.DelayOccurredWhile.WayHome,
                    value: "claim.delayedLuggage.delayType.wayHome"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.DelayedLuggageTravelTypes) {
            return Promise.resolve([
                {
                    id: Constants.DelayedLuggage.TravelTypes.TravelWithinService,
                    value: "claim.delayedLuggage.travelType.withinService"
                },
                {
                    id: Constants.DelayedLuggage.TravelTypes.PrivateTravel,
                    value: "claim.delayedLuggage.travelType.privateTravel"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.DelayedLuggageEventRegion) {
            return Promise.resolve([
                {
                    id: Constants.DelayedLuggage.EventLocation.WithinNordicCountries,
                    value: "claim.delayedLuggage.eventLocation.nordicRegion"
                },
                {
                    id: Constants.DelayedLuggage.EventLocation.OutsideNordicCountries,
                    value: "claim.delayedLuggage.eventLocation.outsideNordicRegion"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.FireIncidentTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {id: Constants.Fire.IncidentTypes.DamageFire, value: "claim.fire.incidentTypes.damageFire"},
                {id: Constants.Fire.IncidentTypes.DamageLightning, value: "claim.fire.incidentTypes.damageLightning"},
                {id: Constants.Fire.IncidentTypes.DamageExplosion, value: "claim.fire.incidentTypes.damageExplosion"},
                {id: Constants.Fire.IncidentTypes.DamageSoot, value: "claim.fire.incidentTypes.damageSoot"},
                {
                    id: Constants.Fire.IncidentTypes.DamageElectricalMalfunction,
                    value: "claim.fire.incidentTypes.damageElectricalMalfunction"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.FireInVacationHomeIncidentTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {id: Constants.FireInVacationHome.IncidentTypes.DamageFire, value: "claim.fireInVacationHome.incidentTypes.damageFire"},
                {id: Constants.FireInVacationHome.IncidentTypes.DamageLightning, value: "claim.fireInVacationHome.incidentTypes.damageLightning"},
                {id: Constants.FireInVacationHome.IncidentTypes.DamageExplosion, value: "claim.fireInVacationHome.incidentTypes.damageExplosion"},
                {id: Constants.FireInVacationHome.IncidentTypes.DamageSoot, value: "claim.fireInVacationHome.incidentTypes.damageSoot"},
                {
                    id: Constants.FireInVacationHome.IncidentTypes.DamageElectricalMalfunction,
                    value: "claim.fireInVacationHome.incidentTypes.damageElectricalMalfunction"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.FireInVacationHomeDamageTypes) {
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.ComputerOrTablet,
                    value: "claim.fireInVacationHome.damagedItemTypes.electronics.computerOrTablet"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Console,
                    value: "claim.fireInVacationHome.damagedItemTypes.electronics.console"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.HomeTheater,
                    value: "claim.fireInVacationHome.damagedItemTypes.electronics.homeTheater"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Television,
                    value: "claim.fireInVacationHome.damagedItemTypes.electronics.television"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Computer,
                    value: "claim.fireInVacationHome.damagedItemTypes.electronics.computer"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Tablet,
                    value: "claim.fireInVacationHome.damagedItemTypes.electronics.tablet"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.OtherElectronics,
                    value: "claim.fireInVacationHome.damagedItemTypes.electronics.otherElectronics"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Other,
                    value: "claim.fireInVacationHome.damagedItemTypes.electronics.other"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Clothes,
                    value: "claim.fireInVacationHome.damagedItemTypes.genericItems.clothes"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Electronics,
                    value: "claim.fireInVacationHome.damagedItemTypes.genericItems.electronics"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.OtherContent,
                    value: "claim.fireInVacationHome.damagedItemTypes.genericItems.otherContent"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Dishwasher,
                    value: "claim.fireInVacationHome.damagedItemTypes.householdItems.dishwasher"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Freezer,
                    value: "claim.fireInVacationHome.damagedItemTypes.householdItems.freezer"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Fridge,
                    value: "claim.fireInVacationHome.damagedItemTypes.householdItems.fridge"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Other,
                    value: "claim.fireInVacationHome.damagedItemTypes.householdItems.other"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.WashingMachine,
                    value: "claim.fireInVacationHome.damagedItemTypes.householdItems.washingMachine"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.FireInVacationHomeBaseDamageTypes) {
            return [
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Electronics,
                    value: "claim.fireInVacationHome.damagedItemTypes.genericItems.electronics"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.PropertyAtCottageEstate,
                    value: "claim.fireInVacationHome.aboutDamagedItems.damage.personalPropertyInCottageEstate.title"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.HouseholdMachineOtherBuilding,
                    value: "claim.fireInVacationHome.aboutDamagedItems.damage.householdMachineInOtherBuilding.title"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.HouseholdMachineResidentialBuilding,
                    value: "claim.fireInVacationHome.aboutDamagedItems.damage.householdMachineInResidentialBuilding.title"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.ResidentialBuilding,
                    value: "claim.fireInVacationHome.aboutDamagedItems.damage.residentialBuilding.title"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.OtherBuilding,
                    value: "claim.fireInVacationHome.aboutDamagedItems.damage.otherBuilding.title"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.OtherInstallationResidentialBuilding,
                    value: "claim.fireInVacationHome.aboutDamagedItems.damage.otherInstallationResidentialBuilding.title"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.OtherInstallationOtherBuilding,
                    value: "claim.fireInVacationHome.aboutDamagedItems.damage.otherInstallationOtherBuilding.title"
                }
            ];
        }

        if (identifier === Constants.ClassifierIdentifier.FireInVacationHomeItemAge) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.ItemAge.LessThanSixMonth,
                    value: "claim.fireInVacationHome.itemAge.lessThanSixMonth"
                },
                {
                    id: Constants.ClassifierGeneralType.ItemAge.SixMonthToOneYear,
                    value: "claim.fireInVacationHome.itemAge.sixMonthToOneYear"
                },
                {id: Constants.ClassifierGeneralType.ItemAge.OneYear, value: "claim.fireInVacationHome.itemAge.oneYear"},
                {id: Constants.ClassifierGeneralType.ItemAge.TwoYears, value: "claim.fireInVacationHome.itemAge.twoYears"},
                {
                    id: Constants.ClassifierGeneralType.ItemAge.ThreeYearsAndOlder,
                    value: "claim.fireInVacationHome.itemAge.threeYearsAndOlder"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.FireIncidentLocations) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.AdditionalSpace,
                    value: "claim.fire.incidentLocation.additionalSpace"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.Apartment,
                    value: "claim.fire.incidentLocation.apartment"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.Cottage,
                    value: "claim.fire.incidentLocation.cottage"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.ForestEstate,
                    value: "claim.fire.incidentLocation.forestEstate"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.OtherLocation,
                    value: "claim.fire.incidentLocation.otherLocation"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.RentalFlat,
                    value: "claim.fire.incidentLocation.rentalFlat"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.SeparatelyInsuredStorage,
                    value: "claim.fire.incidentLocation.separatelyInsuredStorage"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.VillaProperty,
                    value: "claim.fire.incidentLocation.villa"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.Condominium,
                    value: "claim.fire.incidentLocation.condominium"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.CottageEstate,
                    value: "claim.fire.incidentLocation.cottageEstate"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.FireDamageTypes) {
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.ComputerOrTablet,
                    value: "claim.fire.damagedItemTypes.electronics.computerOrTablet"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Console,
                    value: "claim.fire.damagedItemTypes.electronics.console"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.HomeTheater,
                    value: "claim.fire.damagedItemTypes.electronics.homeTheater"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Television,
                    value: "claim.fire.damagedItemTypes.electronics.television"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Computer,
                    value: "claim.fire.damagedItemTypes.electronics.computer"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Tablet,
                    value: "claim.fire.damagedItemTypes.electronics.tablet"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.OtherElectronics,
                    value: "claim.fire.damagedItemTypes.electronics.otherElectronics"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Other,
                    value: "claim.fire.damagedItemTypes.electronics.other"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Clothes,
                    value: "claim.fire.damagedItemTypes.genericItems.clothes"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Electronics,
                    value: "claim.fire.damagedItemTypes.genericItems.electronics"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.OtherContent,
                    value: "claim.fire.damagedItemTypes.genericItems.otherContent"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Dishwasher,
                    value: "claim.fire.damagedItemTypes.householdItems.dishwasher"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Freezer,
                    value: "claim.fire.damagedItemTypes.householdItems.freezer"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Fridge,
                    value: "claim.fire.damagedItemTypes.householdItems.fridge"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Other,
                    value: "claim.fire.damagedItemTypes.householdItems.other"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.WashingMachine,
                    value: "claim.fire.damagedItemTypes.householdItems.washingMachine"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.FireBaseDamageTypes) {
            return [
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Electronics,
                    value: "claim.fire.damagedItemTypes.genericItems.electronics"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.PropertyAtHome,
                    value: "claim.fire.aboutDamagedItems.damage.personalPropertyInHome.title"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.Home,
                    value: "claim.fire.reviewAndSubmit.damage.damageTypes.home"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.PropertyAtVilla,
                    value: "claim.fire.aboutDamagedItems.damage.personalPropertyInVilla.title"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.PropertyAtCottageEstate,
                    value: "claim.fire.aboutDamagedItems.damage.personalPropertyInCottageEstate.title"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.OtherProperty,
                    value: "claim.fire.aboutDamagedItems.damage.personalPropertyInOtherLocation.title"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.HouseholdMachine,
                    value: "claim.fire.aboutDamagedItems.damage.householdMachine.title"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.HouseholdMachineOtherBuilding,
                    value: "claim.fire.aboutDamagedItems.damage.householdMachineInOtherBuilding.title"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.HouseholdMachineResidentialBuilding,
                    value: "claim.fire.aboutDamagedItems.damage.householdMachineInResidentialBuilding.title"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.PersonalPropertyStorage,
                    value: "claim.fire.aboutDamagedItems.damage.personalPropertyInStorage.title"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.GrowingForestOrProducts,
                    value: "claim.fire.aboutDamagedItems.damage.growingForestOrProducts.title"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.PowerWheelchair,
                    value: "claim.fire.aboutDamagedItems.damage.powerWheelchair.title"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.Boat,
                    value: "claim.fire.aboutDamagedItems.damage.boat.title"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.GardenPlot,
                    value: "claim.fire.aboutDamagedItems.damage.gardenPlot.title"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.ResidentialBuilding,
                    value: "claim.fire.aboutDamagedItems.damage.residentialBuilding.title"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.OtherBuilding,
                    value: "claim.fire.aboutDamagedItems.damage.otherBuilding.title"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.StoredProperty,
                    value: "claim.fire.aboutDamagedItems.damage.storedProperty.title"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.OtherInstallationHome,
                    value: "claim.fire.aboutDamagedItems.damage.otherInstallationHome.title"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.OtherInstallationResidentialBuilding,
                    value: "claim.fire.aboutDamagedItems.damage.otherInstallationResidentialBuilding.title"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.OtherInstallationOtherBuilding,
                    value: "claim.fire.aboutDamagedItems.damage.otherInstallationOtherBuilding.title"
                }
            ];
        }

        if (identifier === Constants.ClassifierIdentifier.FireItemAge) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.ItemAge.LessThanSixMonth,
                    value: "claim.fire.itemAge.lessThanSixMonth"
                },
                {
                    id: Constants.ClassifierGeneralType.ItemAge.SixMonthToOneYear,
                    value: "claim.fire.itemAge.sixMonthToOneYear"
                },
                {id: Constants.ClassifierGeneralType.ItemAge.OneYear, value: "claim.fire.itemAge.oneYear"},
                {id: Constants.ClassifierGeneralType.ItemAge.TwoYears, value: "claim.fire.itemAge.twoYears"},
                {
                    id: Constants.ClassifierGeneralType.ItemAge.ThreeYearsAndOlder,
                    value: "claim.fire.itemAge.threeYearsAndOlder"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.GlassIncidentTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.Glass.IncidentTypes.InsulatingGlassLeak,
                    value: "claim.glass.incidentTypes.insulatingGlassLeak"
                },
                {
                    id: Constants.Glass.IncidentTypes.PaneIntentionalDamage,
                    value: "claim.glass.incidentTypes.paneIntentionalDamage"
                },
                {
                    id: Constants.Glass.IncidentTypes.PaneBurglaryAttempt,
                    value: "claim.glass.incidentTypes.paneBurglaryAttempt"
                },
                {id: Constants.Glass.IncidentTypes.PaneOther, value: "claim.glass.incidentTypes.paneOther"},
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.GlassIncidentLocations) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.Apartment,
                    value: "claim.glass.locationOfIncident.apartment"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.Condominium,
                    value: "claim.glass.locationOfIncident.condominium"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.VillaProperty,
                    value: "claim.glass.locationOfIncident.villaProperty"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.RentalFlat,
                    value: "claim.glass.locationOfIncident.rentalFlat"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.SummerHouse,
                    value: "claim.glass.locationOfIncident.summerHouse"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.GlassInVacationHomeIncidentTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.GlassInVacationHome.IncidentTypes.InsulatingGlassLeak,
                    value: "claim.glassInVacationHome.incidentTypes.insulatingGlassLeak"
                },
                {
                    id: Constants.GlassInVacationHome.IncidentTypes.PaneIntentionalDamage,
                    value: "claim.glassInVacationHome.incidentTypes.paneIntentionalDamage"
                },
                {
                    id: Constants.GlassInVacationHome.IncidentTypes.PaneBurglaryAttempt,
                    value: "claim.glassInVacationHome.incidentTypes.paneBurglaryAttempt"
                },
                {
                    id: Constants.GlassInVacationHome.IncidentTypes.PaneOther,
                    value: "claim.glassInVacationHome.incidentTypes.paneOther"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.StormIncidentTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {id: Constants.Storm.IncidentTypes.damageByTree, value: "claim.storm.incidentTypes.damageByTree"},
                {
                    id: Constants.Storm.IncidentTypes.damageByFlyingThings,
                    value: "claim.storm.incidentTypes.damageByFlyingThings"
                },
                {id: Constants.Storm.IncidentTypes.otherDamage, value: "claim.storm.incidentTypes.otherDamage"},
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.StormIncidentLocations) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.Apartment,
                    value: "claim.storm.incidentLocation.apartment"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.Condominium,
                    value: "claim.storm.incidentLocation.condominium"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.OtherLocation,
                    value: "claim.storm.incidentLocation.otherLocation"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.RentalFlat,
                    value: "claim.storm.incidentLocation.rentalFlat"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.VillaEstate,
                    value: "claim.storm.incidentLocation.villaEstate"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.CottageEstate,
                    value: "claim.storm.incidentLocation.cottageEstate"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.StormDamageTypes) {
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.GardenPlot,
                    value: "claim.storm.damagedItemTypes.property.gardenPlot"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.Home,
                    value: "claim.storm.damagedItemTypes.property.home"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.OtherBuilding,
                    value: "claim.storm.damagedItemTypes.property.otherBuilding"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.PersonalProperty,
                    value: "claim.storm.damagedItemTypes.property.personalProperty"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.ResidentialBuilding,
                    value: "claim.storm.damagedItemTypes.property.residentialBuilding"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.StormItemAge) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.ItemAge.LessThanSixMonth,
                    value: "claim.storm.itemAge.lessThanSixMonth"
                },
                {
                    id: Constants.ClassifierGeneralType.ItemAge.SixMonthToOneYear,
                    value: "claim.storm.itemAge.sixMonthToOneYear"
                },
                {id: Constants.ClassifierGeneralType.ItemAge.OneYear, value: "claim.storm.itemAge.oneYear"},
                {id: Constants.ClassifierGeneralType.ItemAge.TwoYears, value: "claim.storm.itemAge.twoYears"},
                {
                    id: Constants.ClassifierGeneralType.ItemAge.ThreeYearsAndOlder,
                    value: "claim.storm.itemAge.threeYearsAndOlder"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.StormInVacationHomeIncidentTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {id: Constants.StormInVacationHome.IncidentTypes.damageByTree, value: "claim.stormInVacationHome.incidentTypes.damageByTree"},
                {
                    id: Constants.StormInVacationHome.IncidentTypes.damageByFlyingThings,
                    value: "claim.stormInVacationHome.incidentTypes.damageByFlyingThings"
                },
                {id: Constants.StormInVacationHome.IncidentTypes.otherDamage, value: "claim.stormInVacationHome.incidentTypes.otherDamage"},
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.StormInVacationHomeDamageTypes) {
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.GardenPlot,
                    value: "claim.stormInVacationHome.damagedItemTypes.property.gardenPlot"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.OtherBuilding,
                    value: "claim.stormInVacationHome.damagedItemTypes.property.otherBuilding"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.PersonalProperty,
                    value: "claim.stormInVacationHome.damagedItemTypes.property.personalProperty"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.ResidentialBuilding,
                    value: "claim.stormInVacationHome.damagedItemTypes.property.residentialBuilding"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.StormInVacationHomeItemAge) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.ItemAge.LessThanSixMonth,
                    value: "claim.stormInVacationHome.itemAge.lessThanSixMonth"
                },
                {
                    id: Constants.ClassifierGeneralType.ItemAge.SixMonthToOneYear,
                    value: "claim.stormInVacationHome.itemAge.sixMonthToOneYear"
                },
                {id: Constants.ClassifierGeneralType.ItemAge.OneYear, value: "claim.stormInVacationHome.itemAge.oneYear"},
                {id: Constants.ClassifierGeneralType.ItemAge.TwoYears, value: "claim.stormInVacationHome.itemAge.twoYears"},
                {
                    id: Constants.ClassifierGeneralType.ItemAge.ThreeYearsAndOlder,
                    value: "claim.stormInVacationHome.itemAge.threeYearsAndOlder"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.TheftIncidentTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.Theft.IncidentTypes.TheftOfEntrievedProperty,
                    value: "claim.theft.incidentTypes.theftOfEntrievedProperty"
                },
                {
                    id: Constants.Theft.IncidentTypes.TheftOrDamageDoneToPropertyInCar,
                    value: "claim.theft.incidentTypes.theftOrDamageDoneToPropertyInCar"
                },
                {id: Constants.Theft.IncidentTypes.TheftFromHotel, value: "claim.theft.incidentTypes.theftFromHotel"},
                {
                    id: Constants.Theft.IncidentTypes.TheftOrDamageDoneToBuildingOrGardenLot,
                    value: "claim.theft.incidentTypes.theftOrDamageDoneToBuildingOrGardenLot"
                },
                {
                    id: Constants.Theft.IncidentTypes.TheftOfPropertyKeptElsewhere,
                    value: "claim.theft.incidentTypes.theftOfPropertyKeptElsewhere"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.TheftInVacationHomeIncidentTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.Theft.IncidentTypes.TheftOfEntrievedProperty,
                    value: "claim.theftInVacationHome.incidentTypes.theftOfEntrievedProperty"
                },
                {
                    id: Constants.Theft.IncidentTypes.TheftOrDamageDoneToBuildingOrGardenLot,
                    value: "claim.theftInVacationHome.incidentTypes.theftOrDamageDoneToBuildingOrGardenLot"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.BurglaryIncidentTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.Burglary.IncidentTypes.TheftOfDamageThroughForcedEntry,
                    value: "claim.burglary.incidentTypes.theftOfDamageThroughForcedEntry"
                },
                {
                    id: Constants.Burglary.IncidentTypes.TheftOfDamageWithoutForcedEntry,
                    value: "claim.burglary.incidentTypes.theftOfDamageWithoutForcedEntry"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.BurglaryInVacationHomeIncidentTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.BurglaryInVacationHome.IncidentTypes.TheftOfDamageThroughForcedEntry,
                    value: "claim.burglaryInVacationHome.incidentTypes.theftOfDamageThroughForcedEntry"
                },
                {
                    id: Constants.BurglaryInVacationHome.IncidentTypes.TheftOfDamageWithoutForcedEntry,
                    value: "claim.burglaryInVacationHome.incidentTypes.theftOfDamageWithoutForcedEntry"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.TheftBurglaryIncidentLocations) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.VillaProperty,
                    value: "claim.theftBurglary.incidentLocation.villaProperty"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.Villa,
                    value: "claim.theftBurglary.incidentLocation.villa"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.VillaEstate,
                    value: "claim.theftBurglary.incidentLocation.villaEstate"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.Cottage,
                    value: "claim.theftBurglary.incidentLocation.cottage"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.CottageEstate,
                    value: "claim.theftBurglary.incidentLocation.cottageEstate"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.Apartment,
                    value: "claim.theftBurglary.incidentLocation.apartment"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.RentalFlat,
                    value: "claim.theftBurglary.incidentLocation.rentalFlat"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.RentalPlace,
                    value: "claim.theftBurglary.incidentLocation.rentalPlace"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.Condominium,
                    value: "claim.theftBurglary.incidentLocation.condominium"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.AdditionalSpace,
                    value: "claim.theftBurglary.incidentLocation.additionalSpace"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.OtherLocation,
                    value: "claim.theftBurglary.incidentLocation.otherLocation"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.CommonLaundryRoom,
                    value: "claim.theftBurglary.incidentLocation.commonLaundryRoom"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.ForestEstate,
                    value: "claim.theftBurglary.incidentLocation.forestEstate"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.HotelRoom,
                    value: "claim.theftBurglary.incidentLocation.hotelRoom"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.InGarageOrOtherwiseOnVillaProperty,
                    value: "claim.theftBurglary.incidentLocation.inGarageOrOtherwiseOnVillaProperty"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.KeptAtArtisanRepairShop,
                    value: "claim.theftBurglary.incidentLocation.keptAtArtisanRepairShop"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.OtherSpaceOnHotel,
                    value: "claim.theftBurglary.incidentLocation.otherSpaceOnHotel"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.ParkedByHome,
                    value: "claim.theftBurglary.incidentLocation.parkedByHome"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.School,
                    value: "claim.theftBurglary.incidentLocation.school"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.SeparatelyInsuredStorage,
                    value: "claim.theftBurglary.incidentLocation.separatelyInsuredStorage"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.BurglaryInVacationHomeIncidentLocations) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.Cottage,
                    value: "claim.burglaryInVacationHome.incidentLocation.cottage"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.TheftInVacationHomeIncidentLocations) {
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.Cottage,
                    value: "claim.theftInVacationHome.incidentLocation.cottage"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.TheftBurglaryDamageTypes) {
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Computer,
                    value: "claim.theftBurglary.damagedItemTypes.electronics.computer"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.ComputerOrTablet,
                    value: "claim.theftBurglary.damagedItemTypes.electronics.computerOrTablet"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Console,
                    value: "claim.theftBurglary.damagedItemTypes.electronics.console"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.HomeTheater,
                    value: "claim.theftBurglary.damagedItemTypes.electronics.homeTheater"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.OtherElectronics,
                    value: "claim.theftBurglary.damagedItemTypes.electronics.otherElectronics"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Tablet,
                    value: "claim.theftBurglary.damagedItemTypes.electronics.tablet"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Television,
                    value: "claim.theftBurglary.damagedItemTypes.electronics.television"
                },

                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Clothes,
                    value: "claim.theftBurglary.damagedItemTypes.genericItems.clothes"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Electronics,
                    value: "claim.theftBurglary.damagedItemTypes.genericItems.electronics"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Glasses,
                    value: "claim.theftBurglary.damagedItemTypes.genericItems.glasses"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Jewelry,
                    value: "claim.theftBurglary.damagedItemTypes.genericItems.jewelry"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.OtherContent,
                    value: "claim.theftBurglary.damagedItemTypes.genericItems.otherContent"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.OtherProperty,
                    value: "claim.theftBurglary.damagedItemTypes.genericItems.otherProperty"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Watches,
                    value: "claim.theftBurglary.damagedItemTypes.genericItems.watches"
                },

                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Combo,
                    value: "claim.theftBurglary.damagedItemTypes.householdItems.combo"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Dishwasher,
                    value: "claim.theftBurglary.damagedItemTypes.householdItems.dishwasher"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Food,
                    value: "claim.theftBurglary.damagedItemTypes.householdItems.food"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Freezer,
                    value: "claim.theftBurglary.damagedItemTypes.householdItems.freezer"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Fridge,
                    value: "claim.theftBurglary.damagedItemTypes.householdItems.fridge"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.FridgeFreezer,
                    value: "claim.theftBurglary.damagedItemTypes.householdItems.fridgeFreezer"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.FridgeFreezerResidentialBuilding,
                    value: "claim.theftBurglary.damagedItemTypes.householdItems.fridgeFreezerResidentialBuilding"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Other,
                    value: "claim.theftBurglary.damagedItemTypes.householdItems.other"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.OtherHouseholdAppliances,
                    value: "claim.theftBurglary.damagedItemTypes.householdItems.otherHouseholdAppliances"
                },

                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.Boat,
                    value: "claim.theftBurglary.damagedItemTypes.property.boat"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.Building,
                    value: "claim.theftBurglary.damagedItemTypes.property.building"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.BuildingAndPersonalProperty,
                    value: "claim.theftBurglary.damagedItemTypes.property.buildingAndPersonalProperty"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.GardenPlot,
                    value: "claim.theftBurglary.damagedItemTypes.property.gardenPlot"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.GrowingForestOrProducts,
                    value: "claim.theftBurglary.damagedItemTypes.property.growingForestOrProducts"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.Home,
                    value: "claim.theftBurglary.damagedItemTypes.property.home"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.Laundry,
                    value: "claim.theftBurglary.damagedItemTypes.property.laundry"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.MountedObjectsOnGardenLot,
                    value: "claim.theftBurglary.damagedItemTypes.property.mountedObjectsOnGardenLot"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.OtherBuilding,
                    value: "claim.theftBurglary.damagedItemTypes.property.otherBuilding"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.PersonalProperty,
                    value: "claim.theftBurglary.damagedItemTypes.property.personalProperty"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.PowerWheelchair,
                    value: "claim.theftBurglary.damagedItemTypes.property.powerWheelchair"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.ResidentialBuilding,
                    value: "claim.theftBurglary.damagedItemTypes.property.residentialBuilding"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.StoredProperty,
                    value: "claim.theftBurglary.damagedItemTypes.property.storedProperty"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.Vegetation,
                    value: "claim.theftBurglary.damagedItemTypes.property.vegetation"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.BurglaryInVacationHomeDamageTypes) {
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Computer,
                    value: "claim.burglaryInVacationHome.damagedItemTypes.electronics.computer"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.ComputerOrTablet,
                    value: "claim.burglaryInVacationHome.damagedItemTypes.electronics.computerOrTablet"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Console,
                    value: "claim.burglaryInVacationHome.damagedItemTypes.electronics.console"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.HomeTheater,
                    value: "claim.burglaryInVacationHome.damagedItemTypes.electronics.homeTheater"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.OtherElectronics,
                    value: "claim.burglaryInVacationHome.damagedItemTypes.electronics.otherElectronics"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Tablet,
                    value: "claim.burglaryInVacationHome.damagedItemTypes.electronics.tablet"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Television,
                    value: "claim.burglaryInVacationHome.damagedItemTypes.electronics.television"
                },

                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Clothes,
                    value: "claim.burglaryInVacationHome.damagedItemTypes.genericItems.clothes"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Electronics,
                    value: "claim.burglaryInVacationHome.damagedItemTypes.genericItems.electronics"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Jewelry,
                    value: "claim.burglaryInVacationHome.damagedItemTypes.genericItems.jewelry"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.OtherProperty,
                    value: "claim.burglaryInVacationHome.damagedItemTypes.genericItems.otherProperty"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Watches,
                    value: "claim.burglaryInVacationHome.damagedItemTypes.genericItems.watches"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.BuildingAndPersonalProperty,
                    value: "claim.burglaryInVacationHome.damagedItemTypes.property.buildingAndPersonalProperty"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.Home,
                    value: "claim.burglaryInVacationHome.damagedItemTypes.property.home"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.PersonalProperty,
                    value: "claim.burglaryInVacationHome.damagedItemTypes.property.personalProperty"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.TheftInVacationHomeDamageTypes) {
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Computer,
                    value: "claim.theftInVacationHome.damagedItemTypes.electronics.computer"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Console,
                    value: "claim.theftInVacationHome.damagedItemTypes.electronics.console"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.HomeTheater,
                    value: "claim.theftInVacationHome.damagedItemTypes.electronics.homeTheater"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.OtherElectronics,
                    value: "claim.theftInVacationHome.damagedItemTypes.electronics.otherElectronics"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Tablet,
                    value: "claim.theftInVacationHome.damagedItemTypes.electronics.tablet"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Television,
                    value: "claim.theftInVacationHome.damagedItemTypes.electronics.television"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Clothes,
                    value: "claim.theftInVacationHome.damagedItemTypes.genericItems.clothes"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Electronics,
                    value: "claim.theftInVacationHome.damagedItemTypes.genericItems.electronics"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Jewelry,
                    value: "claim.theftInVacationHome.damagedItemTypes.genericItems.jewelry"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.OtherProperty,
                    value: "claim.theftInVacationHome.damagedItemTypes.genericItems.otherProperty"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Watches,
                    value: "claim.theftInVacationHome.damagedItemTypes.genericItems.watches"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.MountedObjectsOnGardenLot,
                    value: "claim.theftInVacationHome.damagedItemTypes.property.mountedObjectsOnGardenLot"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.OtherBuilding,
                    value: "claim.theftInVacationHome.damagedItemTypes.property.otherBuilding"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.PersonalProperty,
                    value: "claim.theftInVacationHome.damagedItemTypes.property.personalProperty"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.ResidentialBuilding,
                    value: "claim.theftInVacationHome.damagedItemTypes.property.residentialBuilding"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.Vegetation,
                    value: "claim.theftInVacationHome.damagedItemTypes.property.vegetation"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.TheftBurglaryItemAge) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.ItemAge.LessThanSixMonth,
                    value: "claim.theftBurglary.itemAge.lessThanSixMonth"
                },
                {
                    id: Constants.ClassifierGeneralType.ItemAge.SixMonthToOneYear,
                    value: "claim.theftBurglary.itemAge.sixMonthToOneYear"
                },
                {id: Constants.ClassifierGeneralType.ItemAge.OneYear, value: "claim.theftBurglary.itemAge.oneYear"},
                {id: Constants.ClassifierGeneralType.ItemAge.TwoYears, value: "claim.theftBurglary.itemAge.twoYears"},
                {
                    id: Constants.ClassifierGeneralType.ItemAge.ThreeYearsAndOlder,
                    value: "claim.theftBurglary.itemAge.threeYearsAndOlder"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.BurglaryInVacationHomeItemAge) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.ItemAge.LessThanSixMonth,
                    value: "claim.burglaryInVacationHome.itemAge.lessThanSixMonth"
                },
                {
                    id: Constants.ClassifierGeneralType.ItemAge.SixMonthToOneYear,
                    value: "claim.burglaryInVacationHome.itemAge.sixMonthToOneYear"
                },
                {id: Constants.ClassifierGeneralType.ItemAge.OneYear, value: "claim.burglaryInVacationHome.itemAge.oneYear"},
                {id: Constants.ClassifierGeneralType.ItemAge.TwoYears, value: "claim.burglaryInVacationHome.itemAge.twoYears"},
                {
                    id: Constants.ClassifierGeneralType.ItemAge.ThreeYearsAndOlder,
                    value: "claim.burglaryInVacationHome.itemAge.threeYearsAndOlder"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.TheftInVacationHomeItemAge) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.ItemAge.LessThanSixMonth,
                    value: "claim.theftInVacationHome.itemAge.lessThanSixMonth"
                },
                {
                    id: Constants.ClassifierGeneralType.ItemAge.SixMonthToOneYear,
                    value: "claim.theftInVacationHome.itemAge.sixMonthToOneYear"
                },
                {id: Constants.ClassifierGeneralType.ItemAge.OneYear, value: "claim.theftInVacationHome.itemAge.oneYear"},
                {id: Constants.ClassifierGeneralType.ItemAge.TwoYears, value: "claim.theftInVacationHome.itemAge.twoYears"},
                {
                    id: Constants.ClassifierGeneralType.ItemAge.ThreeYearsAndOlder,
                    value: "claim.theftInVacationHome.itemAge.threeYearsAndOlder"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.TheftBurglaryDamageOrLossItemObtained) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossItemObtained.Heritage,
                    value: "claim.theftBurglary.damageOrLossItemObtained.heritage"
                },
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossItemObtained.Gift,
                    value: "claim.theftBurglary.damageOrLossItemObtained.gift"
                },
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossItemObtained.Purchase,
                    value: "claim.theftBurglary.damageOrLossItemObtained.purchase"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.BurglaryInVacationHomeDamageOrLossItemObtained) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossItemObtained.Heritage,
                    value: "claim.burglaryInVacationHome.damageOrLossItemObtained.heritage"
                },
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossItemObtained.Gift,
                    value: "claim.burglaryInVacationHome.damageOrLossItemObtained.gift"
                },
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossItemObtained.Purchase,
                    value: "claim.burglaryInVacationHome.damageOrLossItemObtained.purchase"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.TheftInVacationHomeDamageOrLossItemObtained) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossItemObtained.Heritage,
                    value: "claim.theftInVacationHome.damageOrLossItemObtained.heritage"
                },
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossItemObtained.Gift,
                    value: "claim.theftInVacationHome.damageOrLossItemObtained.gift"
                },
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossItemObtained.Purchase,
                    value: "claim.theftInVacationHome.damageOrLossItemObtained.purchase"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.TheftBurglaryDamagedStolenItemStatus) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.DamagedStolenItemStatus.Damaged,
                    value: "claim.theftBurglary.damagedStolenItemStatus.damaged"
                },
                {
                    id: Constants.DamagedStolenItemStatus.Stolen,
                    value: "claim.theftBurglary.damagedStolenItemStatus.stolen"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.BurglaryInVacationHomeDamagedStolenItemStatus) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.DamagedStolenItemStatus.Damaged,
                    value: "claim.burglaryInVacationHome.damagedStolenItemStatus.damaged"
                },
                {
                    id: Constants.DamagedStolenItemStatus.Stolen,
                    value: "claim.burglaryInVacationHome.damagedStolenItemStatus.stolen"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.TheftInVacationHomeDamagedStolenItemStatus) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.DamagedStolenItemStatus.Damaged,
                    value: "claim.theftInVacationHome.damagedStolenItemStatus.damaged"
                },
                {
                    id: Constants.DamagedStolenItemStatus.Stolen,
                    value: "claim.theftInVacationHome.damagedStolenItemStatus.stolen"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.NatureIncidentTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {id: Constants.Nature.IncidentTypes.Hailstorm, value: "claim.nature.incidentTypes.hailstorm"},
                {id: Constants.Nature.IncidentTypes.Snow, value: "claim.nature.incidentTypes.snow"},
                {id: Constants.Nature.IncidentTypes.WildAnimals, value: "claim.nature.incidentTypes.wildAnimals"},
                {id: Constants.Nature.IncidentTypes.Earthquake, value: "claim.nature.incidentTypes.earthquake"},
                {
                    id: Constants.Nature.IncidentTypes.ForestDamagePestsOrInsects,
                    value: "claim.nature.incidentTypes.forestDamagePestsOrInsects"
                },
                {
                    id: Constants.Nature.IncidentTypes.ForestDamageDroughtOrFrost,
                    value: "claim.nature.incidentTypes.forestDamageDroughtOrFrost"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.NatureInVacationHomeIncidentTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.Nature.IncidentTypes.Hailstorm,
                    value: "claim.natureInVacationHome.incidentTypes.hailstorm"
                },
                {id: Constants.Nature.IncidentTypes.Snow, value: "claim.natureInVacationHome.incidentTypes.snow"},
                {
                    id: Constants.Nature.IncidentTypes.WildAnimals,
                    value: "claim.natureInVacationHome.incidentTypes.wildAnimals"
                },
                {
                    id: Constants.Nature.IncidentTypes.Earthquake,
                    value: "claim.natureInVacationHome.incidentTypes.earthquake"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.NatureIncidentLocations) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.Apartment,
                    value: "claim.nature.incidentLocation.apartment"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.Condominium,
                    value: "claim.nature.incidentLocation.condominium"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.Cottage,
                    value: "claim.nature.incidentLocation.cottage"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.ForestEstate,
                    value: "claim.nature.incidentLocation.forestEstate"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.OtherLocation,
                    value: "claim.nature.incidentLocation.otherLocation"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.RentalFlat,
                    value: "claim.nature.incidentLocation.rentalFlat"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.SeparatelyInsuredStorage,
                    value: "claim.nature.incidentLocation.separatelyInsuredStorage"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.VillaProperty,
                    value: "claim.nature.incidentLocation.villaProperty"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.NatureInVacationHomeIncidentLocations) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.Cottage,
                    value: "claim.natureInVacationHome.incidentLocation.cottage"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.NatureDamageTypes) {
            return Promise.resolve([

                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.Boat,
                    value: "claim.nature.damagedItemTypes.property.boat"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.GardenPlot,
                    value: "claim.nature.damagedItemTypes.property.gardenPlot"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.Home,
                    value: "claim.nature.damagedItemTypes.property.home"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.OtherBuilding,
                    value: "claim.nature.damagedItemTypes.property.otherBuilding"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.PersonalProperty,
                    value: "claim.nature.damagedItemTypes.property.personalProperty"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.ResidentialBuilding,
                    value: "claim.nature.damagedItemTypes.property.residentialBuilding"
                },
            ]);
        }
        if (identifier === Constants.ClassifierIdentifier.NatureInVacationHomeDamageTypes) {
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.Boat,
                    value: "claim.natureInVacationHome.damagedItemTypes.property.boat"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.GardenPlot,
                    value: "claim.natureInVacationHome.damagedItemTypes.property.gardenPlot"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.OtherBuilding,
                    value: "claim.natureInVacationHome.damagedItemTypes.property.otherBuilding"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.PersonalProperty,
                    value: "claim.natureInVacationHome.damagedItemTypes.property.personalProperty"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.ResidentialBuilding,
                    value: "claim.natureInVacationHome.damagedItemTypes.property.residentialBuilding"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.BicycleItemOwner) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossItemOwner.Mine,
                    value: "claim.bicycle.bicycleItemOwner.mine"
                },
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossItemOwner.OtherFamilyMember,
                    value: "claim.bicycle.bicycleItemOwner.otherFamilyMember"
                },
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossItemOwner.Loaned,
                    value: "claim.bicycle.bicycleItemOwner.loaned"
                },
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossItemOwner.Rented,
                    value: "claim.bicycle.bicycleItemOwner.rented"
                },
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossItemOwner.OtherPersons,
                    value: "claim.bicycle.bicycleItemOwner.otherPersons"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.BicycleItemAge) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.ItemAge.LessThanSixMonth,
                    value: "claim.bicycle.itemAge.lessThanSixMonth"
                },
                {
                    id: Constants.ClassifierGeneralType.ItemAge.SixMonthToOneYear,
                    value: "claim.bicycle.itemAge.sixMonthToOneYear"
                },
                {id: Constants.ClassifierGeneralType.ItemAge.OneYear, value: "claim.bicycle.itemAge.oneYear"},
                {id: Constants.ClassifierGeneralType.ItemAge.TwoYears, value: "claim.bicycle.itemAge.twoYears"},
                {
                    id: Constants.ClassifierGeneralType.ItemAge.ThreeYears,
                    value: "claim.bicycle.itemAge.threeYears"
                },
                {
                    id: Constants.ClassifierGeneralType.ItemAge.FourYears,
                    value: "claim.bicycle.itemAge.fourYears"
                },
                {
                    id: Constants.ClassifierGeneralType.ItemAge.FiveYearsAndOlder,
                    value: "claim.bicycle.itemAge.fiveYearsAndOlder"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.BicycleStatusAtPurchase) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {id: Constants.Bicycle.StatusAtPurchase.New, value: "claim.bicycle.statusAtPurchase.new"},
                {id: Constants.Bicycle.StatusAtPurchase.Used, value: "claim.bicycle.statusAtPurchase.used"}
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.BicyclePurchaseType) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {id: Constants.Bicycle.PurchaseType.Retailer, value: "claim.bicycle.purchaseType.retailer"},
                {id: Constants.Bicycle.PurchaseType.Private, value: "claim.bicycle.purchaseType.private"}
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.BicycleDamageOrLossIncidentTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossIncidentTypes.Damage,
                    value: "claim.bicycle.damageOrLossIncidentTypes.damage"
                },
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossIncidentTypes.Theft,
                    value: "claim.bicycle.damageOrLossIncidentTypes.theft"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.BicycleLocationAbroadTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.Bicycle.IncidentLocationAbroad.InLockedStorage,
                    value: "claim.bicycle.incidentLocationAbroad.onLockedStorage"
                },
                {
                    id: Constants.Bicycle.IncidentLocationAbroad.OtherPlace,
                    value: "claim.bicycle.incidentLocationAbroad.otherPlace"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.BicycleTheftOrLossTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {id: Constants.Bicycle.TheftOrLossTypes.InHome, value: "claim.bicycle.theftOrLossTypes.inHome"},
                {
                    id: Constants.Bicycle.TheftOrLossTypes.InBiAreaOrGarage,
                    value: "claim.bicycle.theftOrLossTypes.inBiAreaOrGarage"
                },
                {id: Constants.Bicycle.TheftOrLossTypes.OtherPlace, value: "claim.bicycle.theftOrLossTypes.otherPlace"},
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.BicycleTheftOrLossOtherPlaceTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.Bicycle.TheftOrLossOtherPlaceTypes.InStorage,
                    value: "claim.bicycle.theftOrLossOtherPlaceTypes.inStorage"
                },
                {
                    id: Constants.Bicycle.TheftOrLossOtherPlaceTypes.OtherPlaceOutside,
                    value: "claim.bicycle.theftOrLossOtherPlaceTypes.otherPlaceOutside"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.InstalledHeaterIncidentLocations) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.InstalledHeater.IncidentLocation.VillaProperty,
                    value: "claim.installedHeater.aboutEvent.locationOfIncident.villaProperty"
                },
                {
                    id: Constants.InstalledHeater.IncidentLocation.Cottage,
                    value: "claim.installedHeater.aboutEvent.locationOfIncident.cottage"
                },
                {
                    id: Constants.InstalledHeater.IncidentLocation.Apartment,
                    value: "claim.installedHeater.aboutEvent.locationOfIncident.apartment"
                },
                {
                    id: Constants.InstalledHeater.IncidentLocation.Condominium,
                    value: "claim.installedHeater.aboutEvent.locationOfIncident.condominium"
                },
                {
                    id: Constants.InstalledHeater.IncidentLocation.RentalPlace,
                    value: "claim.installedHeater.aboutEvent.locationOfIncident.rentalPlace"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.InstalledHeaterInVacationHomeTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.InstalledHeaterInVacationHome.HeaterType.AirConditioner,
                    value: "claim.installedHeaterInVacationHome.aboutEvent.heaterType.airConditioner"
                },
                {
                    id: Constants.InstalledHeaterInVacationHome.HeaterType.AirToWater,
                    value: "claim.installedHeaterInVacationHome.aboutEvent.heaterType.airToWater"
                },
                {
                    id: Constants.InstalledHeaterInVacationHome.HeaterType.EarthHeater,
                    value: "claim.installedHeaterInVacationHome.aboutEvent.heaterType.earthHeater"
                },
                {
                    id: Constants.InstalledHeaterInVacationHome.HeaterType.MountainHeater,
                    value: "claim.installedHeaterInVacationHome.aboutEvent.heaterType.mountainHeater"
                },
                {
                    id: Constants.InstalledHeaterInVacationHome.HeaterType.SeaHeater,
                    value: "claim.installedHeaterInVacationHome.aboutEvent.heaterType.seaHeater"
                },
                {
                    id: Constants.InstalledHeaterInVacationHome.HeaterType.PushairHeater,
                    value: "claim.installedHeaterInVacationHome.aboutEvent.heaterType.pushairHeater"
                },
                {
                    id: Constants.InstalledHeaterInVacationHome.HeaterType.Other,
                    value: "claim.installedHeaterInVacationHome.aboutEvent.heaterType.other"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.InstalledHeaterInVacationHomeYesNoDontKnow) {
            return Promise.resolve([
                {id: Constants.YesNo.Yes, value: "general.yes"},
                {id: Constants.YesNo.DontKnow, value: "general.dontKnow"},
                {id: Constants.YesNo.No, value: "general.no"},
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.InstalledHeaterInVacationHomeAge) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.InstalledHeaterInVacationHome.Age.LessThanEightYears,
                    value: "claim.installedHeaterInVacationHome.aboutInstalledHeaterInVacationHome.ageOfOtherHeater.lessThanEightYears"
                },
                {
                    id: Constants.InstalledHeaterInVacationHome.Age.MoreThanEightYears,
                    value: "claim.installedHeaterInVacationHome.aboutInstalledHeaterInVacationHome.ageOfOtherHeater.moreThanEightYears"
                },
                {
                    id: Constants.InstalledHeaterInVacationHome.Age.LessThanElevenYears,
                    value: "claim.installedHeaterInVacationHome.aboutInstalledHeaterInVacationHome.ageOfOtherHeater.lessThanElevenYears"
                },
                {
                    id: Constants.InstalledHeaterInVacationHome.Age.MoreThanElevenYears,
                    value: "claim.installedHeaterInVacationHome.aboutInstalledHeaterInVacationHome.ageOfOtherHeater.moreThanElevenYears"
                },
                {
                    id: Constants.InstalledHeaterInVacationHome.Age.LessThanSeventeenYears,
                    value: "claim.installedHeaterInVacationHome.aboutInstalledHeaterInVacationHome.ageOfOtherHeater.lessThanSeventeenYears"
                },
                {
                    id: Constants.InstalledHeaterInVacationHome.Age.MoreThanSeventeenYears,
                    value: "claim.installedHeaterInVacationHome.aboutInstalledHeaterInVacationHome.ageOfOtherHeater.moreThanSeventeenYears"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.InstalledHeaterTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.InstalledHeater.HeaterType.AirConditioner,
                    value: "claim.installedHeater.aboutEvent.heaterType.airConditioner"
                },
                {
                    id: Constants.InstalledHeater.HeaterType.AirToWater,
                    value: "claim.installedHeater.aboutEvent.heaterType.airToWater"
                },
                {
                    id: Constants.InstalledHeater.HeaterType.EarthHeater,
                    value: "claim.installedHeater.aboutEvent.heaterType.earthHeater"
                },
                {
                    id: Constants.InstalledHeater.HeaterType.MountainHeater,
                    value: "claim.installedHeater.aboutEvent.heaterType.mountainHeater"
                },
                {
                    id: Constants.InstalledHeater.HeaterType.SeaHeater,
                    value: "claim.installedHeater.aboutEvent.heaterType.seaHeater"
                },
                {
                    id: Constants.InstalledHeater.HeaterType.PushairHeater,
                    value: "claim.installedHeater.aboutEvent.heaterType.pushairHeater"
                },
                {
                    id: Constants.InstalledHeater.HeaterType.Other,
                    value: "claim.installedHeater.aboutEvent.heaterType.other"
                },

            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.InstalledHeaterAge) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.InstalledHeater.Age.LessThanEightYears,
                    value: "claim.installedHeater.aboutInstalledHeater.ageOfOtherHeater.lessThanEightYears"
                },
                {
                    id: Constants.InstalledHeater.Age.MoreThanEightYears,
                    value: "claim.installedHeater.aboutInstalledHeater.ageOfOtherHeater.moreThanEightYears"
                },
                {
                    id: Constants.InstalledHeater.Age.LessThanElevenYears,
                    value: "claim.installedHeater.aboutInstalledHeater.ageOfOtherHeater.lessThanElevenYears"
                },
                {
                    id: Constants.InstalledHeater.Age.MoreThanElevenYears,
                    value: "claim.installedHeater.aboutInstalledHeater.ageOfOtherHeater.moreThanElevenYears"
                },
                {
                    id: Constants.InstalledHeater.Age.LessThanSeventeenYears,
                    value: "claim.installedHeater.aboutInstalledHeater.ageOfOtherHeater.lessThanSeventeenYears"
                },
                {
                    id: Constants.InstalledHeater.Age.MoreThanSeventeenYears,
                    value: "claim.installedHeater.aboutInstalledHeater.ageOfOtherHeater.moreThanSeventeenYears"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.InstalledHeaterYesNoDontKnow) {
            return Promise.resolve([
                {id: Constants.YesNo.Yes, value: "general.yes"},
                {id: Constants.YesNo.DontKnow, value: "general.dontKnow"},
                {id: Constants.YesNo.No, value: "general.no"},
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.PhoneDamageOrLossItemOwner) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {id: Constants.ClassifierGeneralType.DamageOrLossItemOwner.Mine, value: "claims.mobilePhone.common.damageOrLossItemOwner.mine"},
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossItemOwner.OtherFamilyMember,
                    value: "claims.mobilePhone.common.damageOrLossItemOwner.otherFamilyMember"
                },
                {id: Constants.ClassifierGeneralType.DamageOrLossItemOwner.Loaned, value: "claims.mobilePhone.common.damageOrLossItemOwner.loaned"},
                {id: Constants.ClassifierGeneralType.DamageOrLossItemOwner.Rented, value: "claims.mobilePhone.common.damageOrLossItemOwner.rented"},
                {id: Constants.ClassifierGeneralType.DamageOrLossItemOwner.OtherPersons, value: "claims.mobilePhone.common.damageOrLossItemOwner.otherPersons"},
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.OtherDamageOrLossItemOwner) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossItemOwner.Mine,
                    value: "claim.otherDamageOrLoss.aboutItems.damageOrLossItemOwner.mine"
                },
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossItemOwner.OtherFamilyMember,
                    value: "claim.otherDamageOrLoss.aboutItems.damageOrLossItemOwner.otherFamilyMember"
                },
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossItemOwner.Loaned,
                    value: "claim.otherDamageOrLoss.aboutItems.damageOrLossItemOwner.loaned"
                },
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossItemOwner.Rented,
                    value: "claim.otherDamageOrLoss.aboutItems.damageOrLossItemOwner.rented"
                },
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossItemOwner.OtherPersons,
                    value: "claim.otherDamageOrLoss.aboutItems.damageOrLossItemOwner.otherPersons"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.OtherDamageOrLossItemObtained) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossItemObtained.Heritage,
                    value: "claim.otherDamageOrLoss.damageOrLossItemObtained.heritage"
                },
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossItemObtained.Gift,
                    value: "claim.otherDamageOrLoss.damageOrLossItemObtained.gift"
                },
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossItemObtained.Purchase,
                    value: "claim.otherDamageOrLoss.damageOrLossItemObtained.purchase"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.PhoneDamageOrLossIncidentTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {id: Constants.ClassifierGeneralType.DamageOrLossIncidentTypes.Damage, value: "claims.mobilePhone.common.damageOrLossIncidentTypes.damage"},
                {id: Constants.ClassifierGeneralType.DamageOrLossIncidentTypes.Delay, value: "claims.mobilePhone.common.damageOrLossIncidentTypes.delay"},
                {id: Constants.ClassifierGeneralType.DamageOrLossIncidentTypes.Theft, value: "claims.mobilePhone.common.damageOrLossIncidentTypes.theft"},
                {id: Constants.ClassifierGeneralType.DamageOrLossIncidentTypes.TheftOrLoss, value: "claims.mobilePhone.common.damageOrLossIncidentTypes.theftOrLoss"},
                {id: Constants.ClassifierGeneralType.DamageOrLossIncidentTypes.TheftOutOfLuggage, value: "claims.mobilePhone.common.damageOrLossIncidentTypes.theftOutOfLuggage"},
                {id: Constants.ClassifierGeneralType.DamageOrLossIncidentTypes.Loss, value: "claims.mobilePhone.common.damageOrLossIncidentTypes.loss"},
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.OtherDamageOrLossIncidentTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossIncidentTypes.Damage,
                    value: "claim.otherDamageOrLoss.aboutEvent.damageOrLossIncidentTypes.damage"
                },
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossIncidentTypes.TheftOrLoss,
                    value: "claim.otherDamageOrLoss.aboutEvent.damageOrLossIncidentTypes.theftOrLoss"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.OtherDamageOrLossTheftOrLossTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.OtherDamageOrLoss.TheftOrLossTypes.forgottenOrLost,
                    value: "claim.otherDamageOrLoss.aboutEvent.theftOrLossTypes.forgottenOrLost"
                },
                {
                    id: Constants.OtherDamageOrLoss.TheftOrLossTypes.otherLoss,
                    value: "claim.otherDamageOrLoss.aboutEvent.theftOrLossTypes.otherLoss"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.HouseholdAppliancesAge) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.ApplianceAge.LessThanElevenYears,
                    value: "claim.householdAppliances.applianceAge.lessThanElevenYears"
                },
                {
                    id: Constants.ClassifierGeneralType.ApplianceAge.MoreThanElevenYears,
                    value: "claim.householdAppliances.applianceAge.moreThanElevenYears"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.HouseholdAppliancesInVacationHomeAge) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.ApplianceAge.LessThanElevenYears,
                    value: "claim.householdAppliancesInVacationHome.applianceAge.lessThanElevenYears"
                },
                {
                    id: Constants.ClassifierGeneralType.ApplianceAge.MoreThanElevenYears,
                    value: "claim.householdAppliancesInVacationHome.applianceAge.moreThanElevenYears"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.GeneralItemAge) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.ItemAge.LessThanSixMonth,
                    value: "general.itemAge.lessThanSixMonth"
                },
                {
                    id: Constants.ClassifierGeneralType.ItemAge.SixMonthToOneYear,
                    value: "general.itemAge.sixMonthToOneYear"
                },
                {id: Constants.ClassifierGeneralType.ItemAge.OneYear, value: "general.itemAge.oneYear"},
                {id: Constants.ClassifierGeneralType.ItemAge.TwoYears, value: "general.itemAge.twoYears"},
                {
                    id: Constants.ClassifierGeneralType.ItemAge.ThreeYearsAndOlder,
                    value: "general.itemAge.threeYearsAndOlder"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.OtherDamageOrLossItemAge) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.ItemAge.LessThanSixMonth,
                    value: "claim.otherDamageOrLoss.itemAge.lessThanSixMonth"
                },
                {
                    id: Constants.ClassifierGeneralType.ItemAge.SixMonthToOneYear,
                    value: "claim.otherDamageOrLoss.itemAge.sixMonthToOneYear"
                },
                {id: Constants.ClassifierGeneralType.ItemAge.OneYear, value: "claim.otherDamageOrLoss.itemAge.oneYear"},
                {
                    id: Constants.ClassifierGeneralType.ItemAge.TwoYears,
                    value: "claim.otherDamageOrLoss.itemAge.twoYears"
                },
                {
                    id: Constants.ClassifierGeneralType.ItemAge.ThreeYearsAndOlder,
                    value: "claim.otherDamageOrLoss.itemAge.threeYearsAndOlder"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.PhoneDamageOrLossLocations) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {id: Constants.ClassifierGeneralType.DamageOrLossLocations.AtHome, value: "claims.mobilePhone.common.damageOrLossLocations.atHome"},
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossLocations.InNordicRegion,
                    value: "claims.mobilePhone.common.damageOrLossLocations.inNordicRegion"
                },
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossLocations.OutsideNordicRegion,
                    value: "claims.mobilePhone.common.damageOrLossLocations.outsideNordicRegion"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.OtherDamageOrLossLocations) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossLocations.AtHome,
                    value: "claim.otherDamageOrLoss.aboutEvent.damageOrLossLocations.atHome"
                },
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossLocations.InNordicRegion,
                    value: "claim.otherDamageOrLoss.aboutEvent.damageOrLossLocations.inNordicRegion"
                },
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossLocations.OutsideNordicRegion,
                    value: "claim.otherDamageOrLoss.aboutEvent.damageOrLossLocations.outsideNordicRegion"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.PhoneDamageOrLossTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {id: Constants.ClassifierGeneralType.DamageOrLossTypes.Drop, value: "claims.mobilePhone.common.damageOrLossTypes.drop"},
                {id: Constants.ClassifierGeneralType.DamageOrLossTypes.Water, value: "claims.mobilePhone.common.damageOrLossTypes.water"},
                {id: Constants.ClassifierGeneralType.DamageOrLossTypes.Circuit, value: "claims.mobilePhone.common.damageOrLossTypes.circuit"},
                {id: Constants.ClassifierGeneralType.DamageOrLossTypes.Intentional, value: "claims.mobilePhone.common.damageOrLossTypes.intentional"},
                {id: Constants.ClassifierGeneralType.DamageOrLossTypes.Traffic, value: "claims.mobilePhone.common.damageOrLossTypes.traffic"},
                {id: Constants.ClassifierGeneralType.DamageOrLossTypes.Animal, value: "claims.mobilePhone.common.damageOrLossTypes.animal"},
                {id: Constants.ClassifierGeneralType.DamageOrLossTypes.Weather, value: "claims.mobilePhone.common.damageOrLossTypes.weather"},
                {id: Constants.ClassifierGeneralType.DamageOrLossTypes.Other, value: "claims.mobilePhone.common.damageOrLossTypes.other"}
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.OtherDamageOrLossTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossTypes.Drop,
                    value: "claim.otherDamageOrLoss.aboutEvent.damageOrLossTypes.drop"
                },
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossTypes.Water,
                    value: "claim.otherDamageOrLoss.aboutEvent.damageOrLossTypes.water"
                },
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossTypes.Traffic,
                    value: "claim.otherDamageOrLoss.aboutEvent.damageOrLossTypes.traffic"
                },
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossTypes.Weather,
                    value: "claim.otherDamageOrLoss.aboutEvent.damageOrLossTypes.weather"
                },
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossTypes.Other,
                    value: "claim.otherDamageOrLoss.aboutEvent.damageOrLossTypes.other"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.GeneralIncidentLocations) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.AdditionalSpace,
                    value: "general.incidentLocation.additionalSpace"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.Apartment,
                    value: "general.incidentLocation.apartment"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.CommonLaundryRoom,
                    value: "general.incidentLocation.commonLaundryRoom"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.Condominium,
                    value: "general.incidentLocation.condominium"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.Cottage,
                    value: "general.incidentLocation.cottage"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.ForestEstate,
                    value: "general.incidentLocation.forestEstate"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.HotelRoom,
                    value: "general.incidentLocation.hotelRoom"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.InGarageOrOtherwiseOnVillaProperty,
                    value: "general.incidentLocation.inGarageOrOtherwiseOnVillaProperty"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.KeptAtArtisanRepairShop,
                    value: "general.incidentLocation.keptAtArtisanRepairShop"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.OtherLocation,
                    value: "general.incidentLocation.otherLocation"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.OtherSpaceOnHotel,
                    value: "general.incidentLocation.otherSpaceOnHotel"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.ParkedByHome,
                    value: "general.incidentLocation.parkedByHome"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.RentalFlat,
                    value: "general.incidentLocation.rentalFlat"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.RentalPlace,
                    value: "general.incidentLocation.rentalPlace"
                },
                {id: Constants.ClassifierGeneralType.IncidentLocation.School, value: "general.incidentLocation.school"},
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.SeparatelyInsuredStorage,
                    value: "general.incidentLocation.separatelyInsuredStorage"
                },
                {id: Constants.ClassifierGeneralType.IncidentLocation.Villa, value: "general.incidentLocation.villa"},
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.VillaProperty,
                    value: "general.incidentLocation.villaProperty"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.VillaEstate,
                    value: "general.incidentLocation.villaEstate"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.CottageEstate,
                    value: "general.incidentLocation.cottageEstate"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.HouseholdAppliancesIncidentLocations) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.Villa,
                    value: "claim.householdAppliances.incidentLocation.villa"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.Cottage,
                    value: "claim.householdAppliances.incidentLocation.cottage"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.Apartment,
                    value: "claim.householdAppliances.incidentLocation.apartment"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.Condominium,
                    value: "claim.householdAppliances.incidentLocation.condominium"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.RentalPlace,
                    value: "claim.householdAppliances.incidentLocation.rentalPlace"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.GeneralDamageTypes) {
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Computer,
                    value: "general.damagedItemTypes.electronics.computer"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.ComputerOrTablet,
                    value: "general.damagedItemTypes.electronics.computerOrTablet"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Console,
                    value: "general.damagedItemTypes.electronics.console"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.HomeTheater,
                    value: "general.damagedItemTypes.electronics.homeTheater"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.OtherElectronics,
                    value: "general.damagedItemTypes.electronics.otherElectronics"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Tablet,
                    value: "general.damagedItemTypes.electronics.tablet"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Television,
                    value: "general.damagedItemTypes.electronics.television"
                },

                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Clothes,
                    value: "general.damagedItemTypes.genericItems.clothes"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Electronics,
                    value: "general.damagedItemTypes.genericItems.electronics"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Glasses,
                    value: "general.damagedItemTypes.genericItems.glasses"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Jewelry,
                    value: "general.damagedItemTypes.genericItems.jewelry"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.OtherContent,
                    value: "general.damagedItemTypes.genericItems.otherContent"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.OtherProperty,
                    value: "general.damagedItemTypes.genericItems.otherProperty"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Watches,
                    value: "general.damagedItemTypes.genericItems.watches"
                },

                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Combo,
                    value: "general.damagedItemTypes.householdItems.combo"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Dishwasher,
                    value: "general.damagedItemTypes.householdItems.dishwasher"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Food,
                    value: "general.damagedItemTypes.householdItems.food"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Freezer,
                    value: "general.damagedItemTypes.householdItems.freezer"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Fridge,
                    value: "general.damagedItemTypes.householdItems.fridge"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.FridgeFreezer,
                    value: "general.damagedItemTypes.householdItems.fridgeFreezer"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.FridgeFreezerResidentialBuilding,
                    value: "general.damagedItemTypes.householdItems.fridgeFreezerResidentialBuilding"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Other,
                    value: "general.damagedItemTypes.householdItems.other"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.OtherHouseholdAppliances,
                    value: "general.damagedItemTypes.householdItems.otherHouseholdAppliances"
                },

                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.Boat,
                    value: "general.damagedItemTypes.property.boat"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.Building,
                    value: "general.damagedItemTypes.property.building"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.BuildingAndPersonalProperty,
                    value: "general.damagedItemTypes.property.buildingAndPersonalProperty"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.GardenPlot,
                    value: "general.damagedItemTypes.property.gardenPlot"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.GrowingForestOrProducts,
                    value: "general.damagedItemTypes.property.growingForestOrProducts"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.Home,
                    value: "general.damagedItemTypes.property.home"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.Laundry,
                    value: "general.damagedItemTypes.property.laundry"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.MountedObjectsOnGardenLot,
                    value: "general.damagedItemTypes.property.mountedObjectsOnGardenLot"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.OtherBuilding,
                    value: "general.damagedItemTypes.property.otherBuilding"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.PersonalProperty,
                    value: "general.damagedItemTypes.property.personalProperty"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.PowerWheelchair,
                    value: "general.damagedItemTypes.property.powerWheelchair"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.ResidentialBuilding,
                    value: "general.damagedItemTypes.property.residentialBuilding"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.StoredProperty,
                    value: "general.damagedItemTypes.property.storedProperty"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.Vegetation,
                    value: "general.damagedItemTypes.property.vegetation"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.HouseholdAppliancesDamageTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Fridge,
                    value: "claim.householdAppliances.damagedItemTypes.householdItems.fridge"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Freezer,
                    value: "claim.householdAppliances.damagedItemTypes.householdItems.freezer"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Combo,
                    value: "claim.householdAppliances.damagedItemTypes.householdItems.combo"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.FridgeFreezer,
                    value: "claim.householdAppliances.damagedItemTypes.householdItems.fridgeFreezer"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.OtherHouseholdAppliances,
                    value: "claim.householdAppliances.damagedItemTypes.householdItems.otherHouseholdAppliances"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Food,
                    value: "claim.householdAppliances.damagedItemTypes.householdItems.food"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.HouseholdAppliancesInVacationHomeDamageTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Fridge,
                    value: "claim.householdAppliancesInVacationHome.damagedItemTypes.householdItems.fridge"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Freezer,
                    value: "claim.householdAppliancesInVacationHome.damagedItemTypes.householdItems.freezer"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Combo,
                    value: "claim.householdAppliancesInVacationHome.damagedItemTypes.householdItems.combo"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.FridgeFreezer,
                    value: "claim.householdAppliancesInVacationHome.damagedItemTypes.householdItems.fridgeFreezer"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.OtherHouseholdAppliances,
                    value: "claim.householdAppliancesInVacationHome.damagedItemTypes.householdItems.otherHouseholdAppliances"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Food,
                    value: "claim.householdAppliancesInVacationHome.damagedItemTypes.householdItems.food"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.OtherDamageOrLossDamageTypes) {
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Computer,
                    value: "claim.otherDamageOrLoss.damagedItemTypes.electronics.computer"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Console,
                    value: "claim.otherDamageOrLoss.damagedItemTypes.electronics.console"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.HomeTheater,
                    value: "claim.otherDamageOrLoss.damagedItemTypes.electronics.homeTheater"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.OtherElectronics,
                    value: "claim.otherDamageOrLoss.damagedItemTypes.electronics.otherElectronics"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Tablet,
                    value: "claim.otherDamageOrLoss.damagedItemTypes.electronics.tablet"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Electronics.Television,
                    value: "claim.otherDamageOrLoss.damagedItemTypes.electronics.television"
                },

                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Clothes,
                    value: "claim.otherDamageOrLoss.damagedItemTypes.genericItems.clothes"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Electronics,
                    value: "claim.otherDamageOrLoss.damagedItemTypes.genericItems.electronics"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Glasses,
                    value: "claim.otherDamageOrLoss.damagedItemTypes.genericItems.glasses"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Jewelry,
                    value: "claim.otherDamageOrLoss.damagedItemTypes.genericItems.jewelry"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.OtherProperty,
                    value: "claim.otherDamageOrLoss.damagedItemTypes.genericItems.otherProperty"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.Watches,
                    value: "claim.otherDamageOrLoss.damagedItemTypes.genericItems.watches"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.GenericItems.ElectricKickBike,
                    value: "claim.otherDamageOrLoss.damagedItemTypes.genericItems.electricKickBike"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.WaterIncidentLocations) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.Apartment,
                    value: "claim.water.locationOfIncident.apartment"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.Condominium,
                    value: "claim.water.locationOfIncident.condominium"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.VillaEstate,
                    value: "claim.water.locationOfIncident.villaEstate"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.RentalFlat,
                    value: "claim.water.locationOfIncident.rentalFlat"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.CottageEstate,
                    value: "claim.water.locationOfIncident.cottageEstate"
                },
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.OtherLocation,
                    value: "claim.water.locationOfIncident.otherLocation"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.WaterInVacationHomeIncidentLocations) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.IncidentLocation.CottageEstate,
                    value: "claim.waterInVacationHome.locationOfIncident.cottageEstate"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.WaterIncidentTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.Water.IncidentTypes.LeakageFromPipingInstallationWaterproofLayer,
                    value: "claim.water.incidentTypes.leakageFromPipingInstallationWaterproofLayer"
                },
                {
                    id: Constants.Water.IncidentTypes.DamageDueToFlooding,
                    value: "claim.water.incidentTypes.damageDueToFlooding"
                },
                {
                    id: Constants.Water.IncidentTypes.DamageDueToOtherCause,
                    value: "claim.water.incidentTypes.damageDueToOtherCause"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.WaterInVacationHomeIncidentTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.WaterInVacationHome.IncidentTypes.LeakageFromPipingInstallationWaterproofLayer,
                    value: "claim.waterInVacationHome.incidentTypes.leakageFromPipingInstallationWaterproofLayer"
                },
                {
                    id: Constants.WaterInVacationHome.IncidentTypes.DamageDueToFlooding,
                    value: "claim.waterInVacationHome.incidentTypes.damageDueToFlooding"
                },
                {
                    id: Constants.WaterInVacationHome.IncidentTypes.DamageDueToOtherCause,
                    value: "claim.waterInVacationHome.incidentTypes.damageDueToOtherCause"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.WaterLeakOriginOrBreakThroughTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.Water.LeakOriginOrBreakThroughTypes.Piping,
                    value: "claim.water.leakOriginOrBreakThroughTypes.piping"
                },
                {
                    id: Constants.Water.LeakOriginOrBreakThroughTypes.MachineConnectedToPiping,
                    value: "claim.water.leakOriginOrBreakThroughTypes.machineConnectedToPiping"
                },
                {
                    id: Constants.Water.LeakOriginOrBreakThroughTypes.CoolerFreezer,
                    value: "claim.water.leakOriginOrBreakThroughTypes.coolerFreezer"
                },
                {
                    id: Constants.Water.LeakOriginOrBreakThroughTypes.WaterproofLayer,
                    value: "claim.water.leakOriginOrBreakThroughTypes.waterproofLayer"
                },

                {
                    id: Constants.Water.LeakOriginOrBreakThroughTypes.FromGround,
                    value: "claim.water.leakOriginOrBreakThroughTypes.fromGround"
                },
                {
                    id: Constants.Water.LeakOriginOrBreakThroughTypes.ThroughSewerSystem,
                    value: "claim.water.leakOriginOrBreakThroughTypes.throughSewerSystem"
                },
                {
                    id: Constants.Water.LeakOriginOrBreakThroughTypes.ThroughRoof,
                    value: "claim.water.leakOriginOrBreakThroughTypes.throughRoof"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.WaterInVacationHomeLeakOriginOrBreakThroughTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.WaterInVacationHome.LeakOriginOrBreakThroughTypes.Piping,
                    value: "claim.waterInVacationHome.leakOriginOrBreakThroughTypes.piping"
                },
                {
                    id: Constants.WaterInVacationHome.LeakOriginOrBreakThroughTypes.MachineConnectedToPiping,
                    value: "claim.waterInVacationHome.leakOriginOrBreakThroughTypes.machineConnectedToPiping"
                },
                {
                    id: Constants.WaterInVacationHome.LeakOriginOrBreakThroughTypes.CoolerFreezer,
                    value: "claim.waterInVacationHome.leakOriginOrBreakThroughTypes.coolerFreezer"
                },
                {
                    id: Constants.WaterInVacationHome.LeakOriginOrBreakThroughTypes.WaterproofLayer,
                    value: "claim.waterInVacationHome.leakOriginOrBreakThroughTypes.waterproofLayer"
                },
                {
                    id: Constants.WaterInVacationHome.LeakOriginOrBreakThroughTypes.FromGround,
                    value: "claim.waterInVacationHome.leakOriginOrBreakThroughTypes.fromGround"
                },
                {
                    id: Constants.WaterInVacationHome.LeakOriginOrBreakThroughTypes.ThroughSewerSystem,
                    value: "claim.waterInVacationHome.leakOriginOrBreakThroughTypes.throughSewerSystem"
                },
                {
                    id: Constants.WaterInVacationHome.LeakOriginOrBreakThroughTypes.ThroughRoof,
                    value: "claim.waterInVacationHome.leakOriginOrBreakThroughTypes.throughRoof"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.WaterDamageTypes) {
            return Promise.resolve([

                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Combo,
                    value: "claim.water.damagedItemTypes.householdItems.combo"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Food,
                    value: "claim.water.damagedItemTypes.householdItems.food"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Freezer,
                    value: "claim.water.damagedItemTypes.householdItems.freezer"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Fridge,
                    value: "claim.water.damagedItemTypes.householdItems.fridge"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.FridgeFreezer,
                    value: "claim.water.damagedItemTypes.householdItems.fridgeFreezer"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.FridgeFreezerResidentialBuilding,
                    value: "claim.water.damagedItemTypes.householdItems.fridgeFreezerResidentialBuilding"
                },

                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.GardenPlot,
                    value: "claim.water.damagedItemTypes.property.gardenPlot"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.Home,
                    value: "claim.water.damagedItemTypes.property.home"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.OtherBuilding,
                    value: "claim.water.damagedItemTypes.property.otherBuilding"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.PersonalProperty,
                    value: "claim.water.damagedItemTypes.property.personalProperty"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.ResidentialBuilding,
                    value: "claim.water.damagedItemTypes.property.theResidentialBuilding"
                },

            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.WaterInVacationHomeDamageTypes) {
            return Promise.resolve([

                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Combo,
                    value: "claim.waterInVacationHome.damagedItemTypes.householdItems.combo"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Food,
                    value: "claim.waterInVacationHome.damagedItemTypes.householdItems.food"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Freezer,
                    value: "claim.waterInVacationHome.damagedItemTypes.householdItems.freezer"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.Fridge,
                    value: "claim.waterInVacationHome.damagedItemTypes.householdItems.fridge"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.FridgeFreezer,
                    value: "claim.waterInVacationHome.damagedItemTypes.householdItems.fridgeFreezer"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.HouseholdItems.FridgeFreezerResidentialBuilding,
                    value: "claim.waterInVacationHome.damagedItemTypes.householdItems.fridgeFreezerResidentialBuilding"
                },

                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.GardenPlot,
                    value: "claim.waterInVacationHome.damagedItemTypes.property.gardenPlot"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.OtherBuilding,
                    value: "claim.waterInVacationHome.damagedItemTypes.property.otherBuilding"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.PersonalProperty,
                    value: "claim.waterInVacationHome.damagedItemTypes.property.personalProperty"
                },
                {
                    id: Constants.ClassifierGeneralType.DamagedItemTypes.Property.ResidentialBuilding,
                    value: "claim.waterInVacationHome.damagedItemTypes.property.theResidentialBuilding"
                },

            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.WaterDamagedApplianceAge) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.ApplianceAge.LessThanTenYears,
                    value: "claim.water.applianceAge.lessThanTenYears"
                },
                {
                    id: Constants.ClassifierGeneralType.ApplianceAge.MoreThanTenYears,
                    value: "claim.water.applianceAge.moreThanTenYears"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.WaterInVacationHomeDamagedApplianceAge) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.ApplianceAge.LessThanTenYears,
                    value: "claim.waterInVacationHome.applianceAge.lessThanTenYears"
                },
                {
                    id: Constants.ClassifierGeneralType.ApplianceAge.MoreThanTenYears,
                    value: "claim.waterInVacationHome.applianceAge.moreThanTenYears"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.WaterDamagedItemAge) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.ItemAge.LessThanSixMonth,
                    value: "claim.water.itemAge.lessThanSixMonth"
                },
                {
                    id: Constants.ClassifierGeneralType.ItemAge.SixMonthToOneYear,
                    value: "claim.water.itemAge.sixMonthToOneYear"
                },
                {id: Constants.ClassifierGeneralType.ItemAge.OneYear, value: "claim.water.itemAge.oneYear"},
                {id: Constants.ClassifierGeneralType.ItemAge.TwoYears, value: "claim.water.itemAge.twoYears"},
                {
                    id: Constants.ClassifierGeneralType.ItemAge.ThreeYearsAndOlder,
                    value: "claim.water.itemAge.threeYearsAndOlder"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.WaterInVacationHomeDamagedItemAge) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.ItemAge.LessThanSixMonth,
                    value: "claim.waterInVacationHome.itemAge.lessThanSixMonth"
                },
                {
                    id: Constants.ClassifierGeneralType.ItemAge.SixMonthToOneYear,
                    value: "claim.waterInVacationHome.itemAge.sixMonthToOneYear"
                },
                {id: Constants.ClassifierGeneralType.ItemAge.OneYear, value: "claim.waterInVacationHome.itemAge.oneYear"},
                {id: Constants.ClassifierGeneralType.ItemAge.TwoYears, value: "claim.waterInVacationHome.itemAge.twoYears"},
                {
                    id: Constants.ClassifierGeneralType.ItemAge.ThreeYearsAndOlder,
                    value: "claim.waterInVacationHome.itemAge.threeYearsAndOlder"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.IllnessDuringTravelTypeOfSymptoms) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.IllnessDuringTravel.TypeOfSymptoms.Illness,
                    value: "claim.illnessDuringTravel.aboutEvent.typeOfSymptoms.illness"
                },
                {
                    id: Constants.IllnessDuringTravel.TypeOfSymptoms.AcuteDentalProblems,
                    value: "claim.illnessDuringTravel.aboutEvent.typeOfSymptoms.acuteDentalProblems"
                },
                {
                    id: Constants.IllnessDuringTravel.TypeOfSymptoms.WorseningSickness,
                    value: "claim.illnessDuringTravel.aboutEvent.typeOfSymptoms.worseningSickness"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.IllnessDuringTravelMeasuresDuringTravel) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.Measures.DoctorVisited,
                    value: "claim.illnessDuringTravel.measures.doctorVisited"
                },
                {
                    id: Constants.ClassifierGeneralType.Measures.Hospitalization,
                    value: "claim.illnessDuringTravel.measures.hospitalization"
                },
                {id: Constants.ClassifierGeneralType.Measures.Rest, value: "claim.illnessDuringTravel.measures.rest"},
                {
                    id: Constants.ClassifierGeneralType.Measures.AdditionalCosts,
                    value: "claim.illnessDuringTravel.measures.additionalCosts"
                },
                {
                    id: Constants.ClassifierGeneralType.Measures.AbortedTrip,
                    value: "claim.illnessDuringTravel.measures.abortedTrip"
                },
                {
                    id: Constants.ClassifierGeneralType.Measures.DentistVisited,
                    value: "claim.illnessDuringTravel.measures.dentistVisited"
                },
                {
                    id: Constants.ClassifierGeneralType.Measures.DentistVisitedEmergencyCare,
                    value: "claim.illnessDuringTravel.measures.dentistVisitedEmergencyCare"
                },
                {id: Constants.ClassifierGeneralType.Measures.Other, value: "claim.illnessDuringTravel.measures.other"},
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.IllnessDuringTravelTypeOfTravel) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.TypeOfTravel.BusinessTrip,
                    value: "claim.illnessDuringTravel.typeOfTravel.businessTrip"
                },
                {
                    id: Constants.ClassifierGeneralType.TypeOfTravel.ActivityAndSportTravel,
                    value: "claim.illnessDuringTravel.typeOfTravel.activityAndSportTravel"
                },
                {
                    id: Constants.ClassifierGeneralType.TypeOfTravel.Vacation,
                    value: "claim.illnessDuringTravel.typeOfTravel.vacation"
                },
                {
                    id: Constants.ClassifierGeneralType.TypeOfTravel.LongTermStay,
                    value: "claim.illnessDuringTravel.typeOfTravel.longTermStay"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.IllnessDuringTravelTypeOfTransportation) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.TypeOfTransportation.TravelWithOwnCar,
                    value: "claim.illnessDuringTravel.typeOfTransportation.travelWithOwnCar"
                },
                {
                    id: Constants.ClassifierGeneralType.TypeOfTransportation.Flight,
                    value: "claim.illnessDuringTravel.typeOfTransportation.flight"
                },
                {
                    id: Constants.ClassifierGeneralType.TypeOfTransportation.Cruise,
                    value: "claim.illnessDuringTravel.typeOfTransportation.cruise"
                },
                {
                    id: Constants.ClassifierGeneralType.TypeOfTransportation.Other,
                    value: "claim.illnessDuringTravel.typeOfTransportation.other"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.IllnessDuringTravelDestination) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.Destination.Sweden,
                    value: "claim.illnessDuringTravel.destination.sweden"
                },
                {
                    id: Constants.ClassifierGeneralType.Destination.WithinEUOrEES,
                    value: "claim.illnessDuringTravel.destination.withinEUOrEES"
                },
                {
                    id: Constants.ClassifierGeneralType.Destination.OutsideEUOrEES,
                    value: "claim.illnessDuringTravel.destination.outsideEUOrEES"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.IllnessDuringTravelCostCategory) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.CostCategory.MedicalCare,
                    value: "claim.illnessDuringTravel.costCategory.medicalCare"
                },
                {
                    id: Constants.ClassifierGeneralType.CostCategory.Medicine,
                    value: "claim.illnessDuringTravel.costCategory.medicine"
                },
                {
                    id: Constants.ClassifierGeneralType.CostCategory.TravelCaregiver,
                    value: "claim.illnessDuringTravel.costCategory.travelCaregiver"
                },
                {
                    id: Constants.ClassifierGeneralType.CostCategory.Parking,
                    value: "claim.illnessDuringTravel.costCategory.parking"
                },
                {
                    id: Constants.ClassifierGeneralType.CostCategory.CallsFromAbroad,
                    value: "claim.illnessDuringTravel.costCategory.callsFromAbroad"
                },
                {
                    id: Constants.ClassifierGeneralType.CostCategory.ExtendedTrip,
                    value: "claim.illnessDuringTravel.costCategory.extendedTrip"
                },
                {
                    id: Constants.ClassifierGeneralType.CostCategory.Other,
                    value: "claim.illnessDuringTravel.costCategory.other"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.AccidentDuringTravelMeasuresDuringTravel) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.Measures.DoctorVisited,
                    value: "claim.accidentDuringTravel.measures.doctorVisited"
                },
                {
                    id: Constants.ClassifierGeneralType.Measures.Hospitalization,
                    value: "claim.accidentDuringTravel.measures.hospitalization"
                },
                {id: Constants.ClassifierGeneralType.Measures.Rest, value: "claim.accidentDuringTravel.measures.rest"},
                {
                    id: Constants.ClassifierGeneralType.Measures.AdditionalCosts,
                    value: "claim.accidentDuringTravel.measures.additionalCosts"
                },
                {
                    id: Constants.ClassifierGeneralType.Measures.AbortedTrip,
                    value: "claim.accidentDuringTravel.measures.abortedTrip"
                },
                {
                    id: Constants.ClassifierGeneralType.Measures.DentistVisited,
                    value: "claim.accidentDuringTravel.measures.dentistVisited"
                },
                {
                    id: Constants.ClassifierGeneralType.Measures.DentistVisitedEmergencyCare,
                    value: "claim.accidentDuringTravel.measures.dentistVisitedEmergencyCare"
                },
                {
                    id: Constants.ClassifierGeneralType.Measures.Other,
                    value: "claim.accidentDuringTravel.measures.other"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.CancelledTravelTypeOfTravel) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.TypeOfTravel.BusinessTrip,
                    value: "claim.cancelledTravel.typeOfTravel.businessTrip"
                },
                {
                    id: Constants.ClassifierGeneralType.TypeOfTravel.ActivityAndSportTravel,
                    value: "claim.cancelledTravel.typeOfTravel.activityAndSportTravel"
                },
                {
                    id: Constants.ClassifierGeneralType.TypeOfTravel.Vacation,
                    value: "claim.cancelledTravel.typeOfTravel.vacation"
                },
                {
                    id: Constants.ClassifierGeneralType.TypeOfTravel.LongTermStay,
                    value: "claim.cancelledTravel.typeOfTravel.longTermStay"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.AccidentDuringTravelDamageAppliesTo) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.AccidentDuringTravel.DamageAppliesTo.Body,
                    value: "claim.accidentDuringTravel.aboutAccident.damageAppliesTo.body"
                },
                {
                    id: Constants.AccidentDuringTravel.DamageAppliesTo.Teeth,
                    value: "claim.accidentDuringTravel.aboutAccident.damageAppliesTo.teeth"
                },
                {
                    id: Constants.AccidentDuringTravel.DamageAppliesTo.BodyTeeth,
                    value: "claim.accidentDuringTravel.aboutAccident.damageAppliesTo.bodyTeeth"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.TrailerTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.Trailer.Type.Horse,
                    value: `product.trailer.sizeOfInsurance.type.option.${Constants.Trailer.Type.Horse}`
                },
                {
                    id: Constants.Trailer.Type.Other,
                    value: `product.trailer.sizeOfInsurance.type.option.${Constants.Trailer.Type.Other}`
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.CancelledTravelNotificationReferTo) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.CancelledTravel.NotificationReferTo.CancellationOrRebooking,
                    value: "claim.cancelledTravel.aboutEvent.notificationReferTo.cancellationOrRebooking"
                },
                {
                    id: Constants.CancelledTravel.NotificationReferTo.MissedEvent,
                    value: "claim.cancelledTravel.aboutEvent.notificationReferTo.missedEvent"
                },
                {
                    id: Constants.CancelledTravel.NotificationReferTo.CancellationDuringTravel,
                    value: "claim.cancelledTravel.aboutEvent.notificationReferTo.cancellationDuringTravel"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.CancelledTravelNotificationReason) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.CancelledTravel.NotificationReason.AccidentIllnessOrDeath,
                    value: "claim.cancelledTravel.aboutEvent.notificationReason.accidentIllnessOrDeath"
                },
                {
                    id: Constants.CancelledTravel.NotificationReason.DamageOrPropertyLoss,
                    value: "claim.cancelledTravel.aboutEvent.notificationReason.damageOrPropertyLoss"
                },
                {
                    id: Constants.CancelledTravel.NotificationReason.DiseaseOrAccidentForInsuredAnimal,
                    value: "claim.cancelledTravel.aboutEvent.notificationReason.diseaseOrAccidentForInsuredAnimal"
                },
                {
                    id: Constants.CancelledTravel.NotificationReason.OtherReason,
                    value: "claim.cancelledTravel.aboutEvent.notificationReason.otherReason"
                },
                {
                    id: Constants.CancelledTravel.NotificationReason.AcuteDisease,
                    value: "claim.cancelledTravel.aboutEvent.notificationReason.eventOnly.acuteDisease"
                },
                {
                    id: Constants.CancelledTravel.NotificationReason.Accident,
                    value: "claim.cancelledTravel.aboutEvent.notificationReason.eventOnly.accident"
                },
                {
                    id: Constants.CancelledTravel.NotificationReason.DeathOfRelative,
                    value: "claim.cancelledTravel.aboutEvent.notificationReason.eventOnly.deathOfRelative"
                },
                {
                    id: Constants.CancelledTravel.NotificationReason.Other,
                    value: "claim.cancelledTravel.aboutEvent.notificationReason.eventOnly.other"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.CancelledTravelAffectedByIncident) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.CancelledTravel.AffectedByIncident.PersonWithinHousehold,
                    value: "claim.cancelledTravel.aboutEvent.affectedByIncident.personWithinHousehold"
                },
                {
                    id: Constants.CancelledTravel.AffectedByIncident.PersonWithinTravelCompany,
                    value: "claim.cancelledTravel.aboutEvent.affectedByIncident.personWithinTravelCompany"
                },
                {
                    id: Constants.CancelledTravel.AffectedByIncident.OtherCloseRelative,
                    value: "claim.cancelledTravel.aboutEvent.affectedByIncident.otherCloseRelative"
                },
                {
                    id: Constants.CancelledTravel.AffectedByIncident.PersonStayedAt,
                    value: "claim.cancelledTravel.aboutEvent.affectedByIncident.personStayedAt"
                },
                {
                    id: Constants.CancelledTravel.AffectedByIncident.ChildrenOrGrandchildren,
                    value: "claim.cancelledTravel.aboutEvent.affectedByIncident.ChildrenOrGrandchildren"
                },
                {
                    id: Constants.CancelledTravel.AffectedByIncident.BrotherSisterParents,
                    value: "claim.cancelledTravel.aboutEvent.affectedByIncident.BrotherSisterParents"
                },
                {
                    id: Constants.CancelledTravel.AffectedByIncident.GrandmaGrandpa,
                    value: "claim.cancelledTravel.aboutEvent.affectedByIncident.GrandmaGrandpa"
                },
                {
                    id: Constants.CancelledTravel.AffectedByIncident.ParentsOrBrotherSisterInLaw,
                    value: "claim.cancelledTravel.aboutEvent.affectedByIncident.ParentsOrBrotherSisterInLaw"
                },
                {
                    id: Constants.CancelledTravel.AffectedByIncident.OtherPerson,
                    value: "claim.cancelledTravel.aboutEvent.affectedByIncident.otherPerson"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.CancelledTravelCancellationRebooking) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.CancelledTravel.IncidentTypes.Cancellation,
                    value: "claim.cancelledTravel.costs.incidentType.option.cancellation"
                },
                {
                    id: Constants.CancelledTravel.IncidentTypes.Rebooking,
                    value: "claim.cancelledTravel.costs.incidentType.option.rebooking"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.DelayedTravelerDelayDuringTrip) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.DelayedTraveler.DelayDuringTrip.Away,
                    value: "claim.delayedTraveler.aboutEvent.delayDuringTrip.away"
                },
                {
                    id: Constants.DelayedTraveler.DelayDuringTrip.Home,
                    value: "claim.delayedTraveler.aboutEvent.delayDuringTrip.home"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.DelayedTravelerReasonForDelay) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.DelayedTraveler.ReasonForDelay.DelayedPublicTransportation,
                    value: "claim.delayedTraveler.aboutEvent.reasonForDelay.delayedPublicTransportation"
                },
                {
                    id: Constants.DelayedTraveler.ReasonForDelay.CancelledPublicTransportation,
                    value: "claim.delayedTraveler.aboutEvent.reasonForDelay.cancelledPublicTransportation"
                },
                {
                    id: Constants.DelayedTraveler.ReasonForDelay.DelayedTravelWithOwnCar,
                    value: "claim.delayedTraveler.aboutEvent.reasonForDelay.delayedTravelWithOwnCar"
                },
                {
                    id: Constants.DelayedTraveler.ReasonForDelay.Other,
                    value: "claim.delayedTraveler.aboutEvent.reasonForDelay.other"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.DelayedTravelerReasonForDelayWithTransport) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.DelayedTraveler.ReasonForDelayWithTransport.StrikeLockout,
                    value: "claim.delayedTraveler.aboutEvent.reasonForDelay.withTransport.reason.strikeLockout"
                },
                {
                    id: Constants.DelayedTraveler.ReasonForDelayWithTransport.Weather,
                    value: "claim.delayedTraveler.aboutEvent.reasonForDelay.withTransport.reason.weather"
                },
                {
                    id: Constants.DelayedTraveler.ReasonForDelayWithTransport.TraficAccident,
                    value: "claim.delayedTraveler.aboutEvent.reasonForDelay.withTransport.reason.traficAccident"
                },
                {
                    id: Constants.DelayedTraveler.ReasonForDelayWithTransport.OtherTraficRelated,
                    value: "claim.delayedTraveler.aboutEvent.reasonForDelay.withTransport.reason.otherTraficRelated"
                },
                {
                    id: Constants.DelayedTraveler.ReasonForDelayWithTransport.Other,
                    value: "claim.delayedTraveler.aboutEvent.reasonForDelay.withTransport.reason.other"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.HeavyMotorcycleInsuranceType) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.HeavyMotorcycle.InsuranceType.YearRound,
                    value: `product.heavyMotorcycle.sizeOfInsurance.type.option.${Constants.HeavyMotorcycle.InsuranceType.YearRound}`
                },
                {
                    id: Constants.HeavyMotorcycle.InsuranceType.Basic,
                    value: `product.heavyMotorcycle.sizeOfInsurance.type.option.${Constants.HeavyMotorcycle.InsuranceType.Basic}`
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.SnowmobileAtvInsuranceType) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.SnowmobileAtv.InsuranceType.YearRound,
                    value: `product.snowmobileAtv.sizeOfInsurance.type.option.${Constants.SnowmobileAtv.InsuranceType.YearRound}`
                },
                {
                    id: Constants.SnowmobileAtv.InsuranceType.Basic,
                    value: `product.snowmobileAtv.sizeOfInsurance.type.option.${Constants.SnowmobileAtv.InsuranceType.Basic}`
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.MopedType) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.Moped.MopedType.TwoOrTricycle,
                    value: `product.moped.mopedType.option.${Constants.Moped.MopedType.TwoOrTricycle}`
                },
                {
                    id: Constants.Moped.MopedType.Quadbike,
                    value: `product.moped.mopedType.option.${Constants.Moped.MopedType.Quadbike}`
                },
                {
                    id: Constants.Moped.MopedType.ElectricBicycle,
                    value: `product.moped.mopedType.option.${Constants.Moped.MopedType.ElectricBicycle}`,
                    tooltip: {message: `product.moped.mopedType.${Constants.Moped.MopedType.ElectricBicycle}.info`}
                },
                {
                    id: Constants.Moped.MopedType.Mopedbil,
                    value: `product.moped.mopedType.option.${Constants.Moped.MopedType.Mopedbil}`
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.AccidentCoverSelectedFor) {
            return Promise.resolve([
                    {
                        id: Constants.Home.AccidentInsuranceFor.Both,
                        value: `product.homeStudent.accidentAddonDropdown.insuranceFor.both`
                    },
                    {
                        id: Constants.Home.AccidentInsuranceFor.PolicyHolder,
                        value: `product.homeStudent.accidentAddonDropdown.insuranceFor`,
                        title: `product.homeStudent.accidentAddonDropdown.insuranceFor`,
                        messageData: {
                            firstName: pathof(HomeStudentJourneyModel.contact.firstName),
                            lastName: pathof(HomeStudentJourneyModel.contact.lastName),
                        }
                    },
                    {
                        id: Constants.Home.AccidentInsuranceFor.CoInsured,
                        value: "product.homeStudent.accidentAddonDropdown.insuranceFor",
                        messageData: {
                            firstName: pathof(HomeStudentJourneyModel.policy.insuredObject.roomMate.firstName),
                            lastName: pathof(HomeStudentJourneyModel.policy.insuredObject.roomMate.lastName),
                        }
                    }
                ]
            )
        }


        if (identifier === Constants.ClassifierIdentifier.AccidentDuringTravelTypeOfTravel) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.TypeOfTravel.BusinessTrip,
                    value: "claim.accidentDuringTravel.typeOfTravel.businessTrip"
                },
                {
                    id: Constants.ClassifierGeneralType.TypeOfTravel.ActivityAndSportTravel,
                    value: "claim.accidentDuringTravel.typeOfTravel.activityAndSportTravel"
                },
                {
                    id: Constants.ClassifierGeneralType.TypeOfTravel.Vacation,
                    value: "claim.accidentDuringTravel.typeOfTravel.vacation"
                },
                {
                    id: Constants.ClassifierGeneralType.TypeOfTravel.LongTermStay,
                    value: "claim.accidentDuringTravel.typeOfTravel.longTermStay"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.AccidentDuringTravelTypeOfTransportation) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.TypeOfTransportation.TravelWithOwnCar,
                    value: "claim.accidentDuringTravel.typeOfTransportation.travelWithOwnCar"
                },
                {
                    id: Constants.ClassifierGeneralType.TypeOfTransportation.Flight,
                    value: "claim.accidentDuringTravel.typeOfTransportation.flight"
                },
                {
                    id: Constants.ClassifierGeneralType.TypeOfTransportation.Cruise,
                    value: "claim.accidentDuringTravel.typeOfTransportation.cruise"
                },
                {
                    id: Constants.ClassifierGeneralType.TypeOfTransportation.Other,
                    value: "claim.accidentDuringTravel.typeOfTransportation.other"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.AccidentDuringTravelDestination) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.Destination.Sweden,
                    value: "claim.accidentDuringTravel.destination.sweden"
                },
                {
                    id: Constants.ClassifierGeneralType.Destination.WithinEUOrEES,
                    value: "claim.accidentDuringTravel.destination.withinEUOrEES"
                },
                {
                    id: Constants.ClassifierGeneralType.Destination.OutsideEUOrEES,
                    value: "claim.accidentDuringTravel.destination.outsideEUOrEES"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.AccidentDuringTravelCostCategory) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.CostCategory.MedicalCare,
                    value: "claim.accidentDuringTravel.costCategory.medicalCare"
                },
                {
                    id: Constants.ClassifierGeneralType.CostCategory.Medicine,
                    value: "claim.accidentDuringTravel.costCategory.medicine"
                },
                {
                    id: Constants.ClassifierGeneralType.CostCategory.TravelCaregiver,
                    value: "claim.accidentDuringTravel.costCategory.travelCaregiver"
                },
                {
                    id: Constants.ClassifierGeneralType.CostCategory.Parking,
                    value: "claim.accidentDuringTravel.costCategory.parking"
                },
                {
                    id: Constants.ClassifierGeneralType.CostCategory.CallsFromAbroad,
                    value: "claim.accidentDuringTravel.costCategory.callsFromAbroad"
                },
                {
                    id: Constants.ClassifierGeneralType.CostCategory.ExtendedTrip,
                    value: "claim.accidentDuringTravel.costCategory.extendedTrip"
                },
                {
                    id: Constants.ClassifierGeneralType.CostCategory.Other,
                    value: "claim.accidentDuringTravel.costCategory.other"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.InjuryCostType) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.InjuryCostType.Clothes,
                    value: "claim.personalAccident.costs.injuryCost.type.clothes"
                },
                {
                    id: Constants.InjuryCostType.Glasses,
                    value: "claim.personalAccident.costs.injuryCost.type.glasses"
                },
                {
                    id: Constants.InjuryCostType.HomeAssistance,
                    value: "claim.personalAccident.costs.injuryCost.type.homeAssistance"
                },
                {
                    id: Constants.InjuryCostType.OtherCost,
                    value: "claim.personalAccident.costs.injuryCost.type.otherCost"
                },

            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.InjuryCostItemAge) {
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.ItemAge.LessThanSixMonth,
                    value: "claim.personalAccident.costs.injuryCost.age.lessThanSixMonth"
                },
                {
                    id: Constants.ClassifierGeneralType.ItemAge.SixMonthToOneYear,
                    value: "claim.personalAccident.costs.injuryCost.age.sixMonthToOneYear"
                },
                {
                    id: Constants.ClassifierGeneralType.ItemAge.OneYear,
                    value: "claim.personalAccident.costs.injuryCost.age.oneYear"
                },
                {
                    id: Constants.ClassifierGeneralType.ItemAge.TwoYears,
                    value: "claim.personalAccident.costs.injuryCost.age.twoYears"
                },
                {
                    id: Constants.ClassifierGeneralType.ItemAge.ThreeYears,
                    value: "claim.personalAccident.costs.injuryCost.age.threeYears"
                },
                {
                    id: Constants.ClassifierGeneralType.ItemAge.FourYears,
                    value: "claim.personalAccident.costs.injuryCost.age.fourYears"
                },
                {
                    id: Constants.ClassifierGeneralType.ItemAge.FiveYearsAndOlder,
                    value: "claim.personalAccident.costs.injuryCost.age.fiveYearsAndOlder"
                },
            ])
        }

        if (identifier === Constants.ClassifierIdentifier.DelayedLuggageIncidentTypes) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossIncidentTypes.Delay,
                    value: "claim.delayedLuggage.incidentType.delay"
                },
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossIncidentTypes.Damage,
                    value: "claim.delayedLuggage.incidentType.damage"
                },
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossIncidentTypes.Loss,
                    value: "claim.delayedLuggage.incidentType.loss"
                },
                {
                    id: Constants.ClassifierGeneralType.DamageOrLossIncidentTypes.TheftOutOfLuggage,
                    value: "claim.delayedLuggage.incidentType.theftOutOfLuggage"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.DelayedLuggageInsuranceCompanies) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {id: Constants.InsuranceCompanies.Dina, value: "claim.delayedLuggage.insuranceCompany.dina"},
                {
                    id: Constants.InsuranceCompanies.Gjensidige,
                    value: "claim.delayedLuggage.insuranceCompany.gjensidige"
                },
                {id: Constants.InsuranceCompanies.ICA, value: "claim.delayedLuggage.insuranceCompany.ica"},
                {id: Constants.InsuranceCompanies.IF, value: "claim.delayedLuggage.insuranceCompany.if"},
                {
                    id: Constants.InsuranceCompanies.Lansforsakringar,
                    value: "claim.delayedLuggage.insuranceCompany.lansforsakringar"
                },
                {id: Constants.InsuranceCompanies.Moderna, value: "claim.delayedLuggage.insuranceCompany.moderna"},
                {id: Constants.InsuranceCompanies.TreKronor, value: "claim.delayedLuggage.insuranceCompany.treKronor"},
                {
                    id: Constants.InsuranceCompanies.TryggHansa,
                    value: "claim.delayedLuggage.insuranceCompany.tryggHansa"
                },
                {id: Constants.InsuranceCompanies.Other, value: "claim.delayedLuggage.insuranceCompany.other"}
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.VehicleCollisionAreasDamaged) {
            return Promise.resolve([
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.Front,
                    value: "claim.vehicleCollision.aboutDamage.selectVehicleDamage.area.front"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.Back,
                    value: "claim.vehicleCollision.aboutDamage.selectVehicleDamage.area.back"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.PassengerSide,
                    value: "claim.vehicleCollision.aboutDamage.selectVehicleDamage.area.passengerSide"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.DriverSide,
                    value: "claim.vehicleCollision.aboutDamage.selectVehicleDamage.area.driverSide"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.Roof,
                    value: "claim.vehicleCollision.aboutDamage.selectVehicleDamage.area.roof"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.Underside,
                    value: "claim.vehicleCollision.aboutDamage.selectVehicleDamage.area.underside"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.VehicleSingleAccidentAreasDamaged) {
            return Promise.resolve([
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.Front,
                    value: "claim.vehicleSingleAccident.aboutDamage.selectVehicleDamage.area.front"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.Back,
                    value: "claim.vehicleSingleAccident.aboutDamage.selectVehicleDamage.area.back"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.PassengerSide,
                    value: "claim.vehicleSingleAccident.aboutDamage.selectVehicleDamage.area.passengerSide"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.DriverSide,
                    value: "claim.vehicleSingleAccident.aboutDamage.selectVehicleDamage.area.driverSide"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.Roof,
                    value: "claim.vehicleSingleAccident.aboutDamage.selectVehicleDamage.area.roof"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.Underside,
                    value: "claim.vehicleSingleAccident.aboutDamage.selectVehicleDamage.area.underside"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.VehicleHitAndRunAccidentAreasDamaged) {
            return Promise.resolve([
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.Front,
                    value: "claim.vehicleHitAndRunAccident.aboutDamage.selectVehicleDamage.area.front"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.Back,
                    value: "claim.vehicleHitAndRunAccident.aboutDamage.selectVehicleDamage.area.back"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.PassengerSide,
                    value: "claim.vehicleHitAndRunAccident.aboutDamage.selectVehicleDamage.area.passengerSide"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.DriverSide,
                    value: "claim.vehicleHitAndRunAccident.aboutDamage.selectVehicleDamage.area.driverSide"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.Roof,
                    value: "claim.vehicleHitAndRunAccident.aboutDamage.selectVehicleDamage.area.roof"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.Underside,
                    value: "claim.vehicleHitAndRunAccident.aboutDamage.selectVehicleDamage.area.underside"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.VehicleTheftOrBurglaryAccidentAreasDamaged) {
            return Promise.resolve([
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.Front,
                    value: "claim.vehicleTheftOrBurglary.aboutDamage.vehicle.damage.area.front"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.Back,
                    value: "claim.vehicleTheftOrBurglary.aboutDamage.vehicle.damage.area.back"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.PassengerSide,
                    value: "claim.vehicleTheftOrBurglary.aboutDamage.vehicle.damage.area.passengerSide"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.DriverSide,
                    value: "claim.vehicleTheftOrBurglary.aboutDamage.vehicle.damage.area.driverSide"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.Roof,
                    value: "claim.vehicleTheftOrBurglary.aboutDamage.vehicle.damage.area.roof"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.Underside,
                    value: "claim.vehicleTheftOrBurglary.aboutDamage.vehicle.damage.area.underside"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.VehicleVandalismAreasDamaged) {
            return Promise.resolve([
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.Front,
                    value: "claim.vehicleVandalism.aboutDamage.selectVehicleDamage.area.front"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.Back,
                    value: "claim.vehicleVandalism.aboutDamage.selectVehicleDamage.area.back"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.PassengerSide,
                    value: "claim.vehicleVandalism.aboutDamage.selectVehicleDamage.area.passengerSide"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.DriverSide,
                    value: "claim.vehicleVandalism.aboutDamage.selectVehicleDamage.area.driverSide"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.Roof,
                    value: "claim.vehicleVandalism.aboutDamage.selectVehicleDamage.area.roof"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.Underside,
                    value: "claim.vehicleVandalism.aboutDamage.selectVehicleDamage.area.underside"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.VehicleTheftOrBurglaryItemAge) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.ClassifierGeneralType.ItemAge.OneYear,
                    value: "claim.vehicleTheftOrBurglary.itemAge.oneYear"
                },
                {
                    id: Constants.ClassifierGeneralType.ItemAge.TwoYears,
                    value: "claim.vehicleTheftOrBurglary.itemAge.twoYears"
                },
                {
                    id: Constants.ClassifierGeneralType.ItemAge.ThreeYears,
                    value: "claim.vehicleTheftOrBurglary.itemAge.threeYears"
                },
                {
                    id: Constants.ClassifierGeneralType.ItemAge.FourYearsAndOlder,
                    value: "claim.vehicleTheftOrBurglary.itemAge.fourYearsAndOlder"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.VehicleTheftOrBurglaryParkedLocation) {
            // @TODO: remove mocked data, when api available
            return Promise.resolve([
                {
                    id: Constants.Vehicle.ParkedLocation.AtMyProperty,
                    value: "claim.vehicleTheftOrBurglary.parkedLocation.atMyProperty"
                },
                {
                    id: Constants.Vehicle.ParkedLocation.InMyGarage,
                    value: "claim.vehicleTheftOrBurglary.parkedLocation.inMyGarage"
                },
                {
                    id: Constants.Vehicle.ParkedLocation.OtherPlace,
                    value: "claim.vehicleTheftOrBurglary.parkedLocation.otherPlace"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.VehicleCollisionWithAnimalAnimalKind) {
            return Promise.resolve([
                {
                    id: Constants.VehicleCollisionWithAnimal.AnimalKind.Dog,
                    value: "claim.vehicleCollisionWithAnimal.aboutDamage.animalKind.dog"
                },
                {
                    id: Constants.VehicleCollisionWithAnimal.AnimalKind.Horse,
                    value: "claim.vehicleCollisionWithAnimal.aboutDamage.animalKind.horse"
                },
                {
                    id: Constants.VehicleCollisionWithAnimal.AnimalKind.Cat,
                    value: "claim.vehicleCollisionWithAnimal.aboutDamage.animalKind.cat"
                },
                {
                    id: Constants.VehicleCollisionWithAnimal.AnimalKind.FarmAnimal,
                    value: "claim.vehicleCollisionWithAnimal.aboutDamage.animalKind.farmAnimal"
                },
                {
                    id: Constants.VehicleCollisionWithAnimal.AnimalKind.Reindeer,
                    value: "claim.vehicleCollisionWithAnimal.aboutDamage.animalKind.reindeer"
                },
                {
                    id: Constants.VehicleCollisionWithAnimal.AnimalKind.Deer,
                    value: "claim.vehicleCollisionWithAnimal.aboutDamage.animalKind.deer"
                },
                {
                    id: Constants.VehicleCollisionWithAnimal.AnimalKind.Boar,
                    value: "claim.vehicleCollisionWithAnimal.aboutDamage.animalKind.boar"
                },
                {
                    id: Constants.VehicleCollisionWithAnimal.AnimalKind.Moose,
                    value: "claim.vehicleCollisionWithAnimal.aboutDamage.animalKind.moose"
                },
                {
                    id: Constants.VehicleCollisionWithAnimal.AnimalKind.Other,
                    value: "claim.vehicleCollisionWithAnimal.aboutDamage.animalKind.other"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.VehicleCollisionWithAnimalAreasDamaged) {
            return Promise.resolve([
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.Front,
                    value: "claim.vehicleCollisionWithAnimal.aboutDamage.vehicle.damage.area.front"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.Back,
                    value: "claim.vehicleCollisionWithAnimal.aboutDamage.vehicle.damage.area.back"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.PassengerSide,
                    value: "claim.vehicleCollisionWithAnimal.aboutDamage.vehicle.damage.area.passengerSide"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.DriverSide,
                    value: "claim.vehicleCollisionWithAnimal.aboutDamage.vehicle.damage.area.driverSide"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.Roof,
                    value: "claim.vehicleCollisionWithAnimal.aboutDamage.vehicle.damage.area.roof"
                },
                {
                    id: Constants.Vehicle.VehicleAreasDamaged.Underside,
                    value: "claim.vehicleCollisionWithAnimal.aboutDamage.vehicle.damage.area.underside"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.VehicleLossOfKeyType) {
            return Promise.resolve([
                {id: Constants.VehicleLossOfKey.Type.Lost, value: "claim.vehicleLossOfKey.aboutEvent.type.lost"},
                {id: Constants.VehicleLossOfKey.Type.Stolen, value: "claim.vehicleLossOfKey.aboutEvent.type.stolen"},
                {id: Constants.VehicleLossOfKey.Type.Damaged, value: "claim.vehicleLossOfKey.aboutEvent.type.damaged"}
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.CatBreedTypes) {
            return this.getTableValues(
                Workflow.Classifiers.PetBreedOptions,
                {type: PetTypes.cat},
                locale,
                this.getCustomFields(identifier)
            )
        }

        if (identifier === Constants.ClassifierIdentifier.HomeProductGroups) {
            return this.getTableValues(
                Workflow.Classifiers.HomeProductGroups,
                !!params ? params : {},
                locale,
                this.getCustomFields(identifier)
            )
        }

        if (identifier === Constants.ClassifierIdentifier.DogBreedTypes) {
            return this.getTableValues(
                Workflow.Classifiers.PetBreedOptions,
                {type: PetTypes.dog},
                locale,
                this.getCustomFields(identifier)
            )
        }

        if (identifier === Constants.ClassifierIdentifier.PetIdentifierTypes) {
            return Promise.resolve([
                {
                    id: Constants.Pet.IdentifierType.Chip,
                    value: `general.pet.identifier.chip`
                },
                {
                    id: Constants.Pet.IdentifierType.RegNumber,
                    value: `general.pet.identifier.regNumber`
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.PetVetDateOptions) {
            return Promise.resolve([
                {
                    id: Constants.Pet.VetDates.Today,
                    value: "general.pet.vetDates.today"
                },
                {
                    id: Constants.Pet.VetDates.ThisWeek,
                    value: "general.pet.vetDates.thisWeek"
                },
                {
                    id: Constants.Pet.VetDates.LessThanOneMonthAgo,
                    value: "general.pet.vetDates.lessThanOneMonth"
                },
                {
                    id: Constants.Pet.VetDates.OneToThreeMonthsAgo,
                    value: "general.pet.vetDates.oneToThreeMonthsAgo"
                },
                {
                    id: Constants.Pet.VetDates.ThreeToFiveMonthsAgo,
                    value: "general.pet.vetDates.threeToFiveMonthsAgo"
                },
                {
                    id: Constants.Pet.VetDates.SixToElevenMonthsAgo,
                    value: "general.pet.vetDates.sixToElevenMonthsAgo"
                },
                {
                    id: Constants.Pet.VetDates.OneYearAgo,
                    value: "general.pet.vetDates.oneYearAgo"
                },
                {
                    id: Constants.Pet.VetDates.TwoYearsAgo,
                    value: "general.pet.vetDates.twoYearsAgo"
                },
                {
                    id: Constants.Pet.VetDates.MoreThanThreeYearsAgo,
                    value: "general.pet.vetDates.moreThanThreeYearsAgo"
                },
                {
                    id: Constants.Pet.VetDates.DontRemember,
                    value: "general.pet.vetDates.dontRemember"
                },
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.BicycleType) {
            return Promise.resolve([
                {
                    id: Constants.Bicycle.BicycleType.Bicycle,
                    value: "claim.bicycle.bicycleType.bicycle"
                },
                {
                    id: Constants.Bicycle.BicycleType.ElectricBike,
                    value: "claim.bicycle.bicycleType.electricBike"
                },
                {
                    id: Constants.Bicycle.BicycleType.ElectricKickBike,
                    value: "claim.bicycle.bicycleType.electricKickBike"
                },
            ] as ReferenceTableValue[]);
        }

        if (identifier === Constants.ClassifierIdentifier.ClaimCauseOfLoss) {
            return Promise.resolve([
                {
                    id: Constants.ClaimCauseOfLoss.AccidentSpareTime,
                    value: "claim.personalAccident.aboutAccident.cause.spareTime"
                },
                {
                    id: Constants.ClaimCauseOfLoss.AccidentDuringWork,
                    value: 'claim.personalAccident.aboutAccident.cause.duringWork'
                },
                {
                    id: Constants.ClaimCauseOfLoss.AccidentDuringOrganizedSportsActivity,
                    value: 'claim.personalAccident.aboutAccident.cause.organizedSportsActivity'
                },
                {
                    id: Constants.ClaimCauseOfLoss.AccidentForParticipants,
                    value: 'claim.personalAccident.aboutAccident.cause.otherOrganizedSportsActivity'
                }
            ])
        }

        if (identifier === Constants.ClassifierIdentifier.BodyParts) {
            return Promise.resolve([
                    {
                        id: Constants.BodyParts.Area.HeadAndNeck,
                        value: "aboutBodilyInjury.bodyAreaInjured.headAndNeck",
                        lvl1Id: "",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Area.RightArm,
                        value: "aboutBodilyInjury.bodyAreaInjured.rightHand",
                        lvl1Id: "",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Area.RightLeg,
                        value: "aboutBodilyInjury.bodyAreaInjured.rightLeg",
                        lvl1Id: "",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Area.Torso,
                        value: "aboutBodilyInjury.bodyAreaInjured.corps",
                        lvl1Id: "",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Area.LeftArm,
                        value: "aboutBodilyInjury.bodyAreaInjured.leftHand",
                        lvl1Id: "",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Area.LeftLeg,
                        value: "aboutBodilyInjury.bodyAreaInjured.leftLeg",
                        lvl1Id: "",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.HeadAndNeck.HeadOrFace,
                        value: "aboutBodilyInjury.bodyParts.headFace",
                        lvl1Id: "1000001",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.HeadAndNeck.Neck,
                        value: "aboutBodilyInjury.bodyParts.neck",
                        lvl1Id: "1000001",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.HeadAndNeck.SeveralOptionsAbove,
                        value: "aboutBodilyInjury.bodyParts.several",
                        lvl1Id: "1000001",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.Torso.Shoulder,
                        value: "aboutBodilyInjury.torso.shoulder",
                        lvl1Id: "1000002",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.Torso.Torso,
                        value: "aboutBodilyInjury.torso.torso",
                        lvl1Id: "1000002",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.Torso.Collarbone,
                        value: "aboutBodilyInjury.torso.collarbone",
                        lvl1Id: "1000002",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.Torso.RibOrRibs,
                        value: "aboutBodilyInjury.torso.ribs",
                        lvl1Id: "1000002",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.Torso.Back,
                        value: "aboutBodilyInjury.torso.back",
                        lvl1Id: "1000002",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.Torso.SeveralOptionsAbove,
                        value: "aboutBodilyInjury.torso.several",
                        lvl1Id: "1000002",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.RightArm.ElbowOrForearm,
                        value: "aboutBodilyInjury.rightHand.elbowForearm",
                        lvl1Id: "1000003",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.RightArm.FingerOrFingers,
                        value: "aboutBodilyInjury.rightHand.fingers",
                        lvl1Id: "1000003",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.RightArm.Hand,
                        value: "aboutBodilyInjury.rightHand.hand",
                        lvl1Id: "1000003",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.RightArm.Wrist,
                        value: "aboutBodilyInjury.rightHand.wrist",
                        lvl1Id: "1000003",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.RightArm.Thumb,
                        value: "aboutBodilyInjury.rightHand.thumb",
                        lvl1Id: "1000003",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.RightArm.UpperArmOrShoulder,
                        value: "aboutBodilyInjury.rightHand.upperArmOrShoulder",
                        lvl1Id: "1000003",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.RightArm.SeveralOptionsAbove,
                        value: "aboutBodilyInjury.rightHand.several",
                        lvl1Id: "1000003",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.LeftArm.ElbowOrForearm,
                        value: "aboutBodilyInjury.leftHand.elbowForearm",
                        lvl1Id: "1000004",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.LeftArm.FingerOrFingers,
                        value: "aboutBodilyInjury.leftHand.fingers",
                        lvl1Id: "1000004",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.LeftArm.Hand,
                        value: "aboutBodilyInjury.leftHand.hand",
                        lvl1Id: "1000004",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.LeftArm.Wrist,
                        value: "aboutBodilyInjury.leftHand.wrist",
                        lvl1Id: "1000004",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.LeftArm.Thumb,
                        value: "aboutBodilyInjury.leftHand.thumb",
                        lvl1Id: "1000004",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.LeftArm.UpperArmOrShoulder,
                        value: "aboutBodilyInjury.leftHand.upperArmOrShoulder",
                        lvl1Id: "1000004",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.LeftArm.SeveralOptionsAbove,
                        value: "aboutBodilyInjury.leftHand.several",
                        lvl1Id: "1000004",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.RightLeg.FootOrFeet,
                        value: "aboutBodilyInjury.rightLeg.footFeet",
                        lvl1Id: "1000005",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.RightLeg.Ankle,
                        value: "aboutBodilyInjury.rightLeg.ankle",
                        lvl1Id: "1000005",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.RightLeg.HeelOrAchillesTendon,
                        value: "aboutBodilyInjury.rightLeg.heelAchilesTendon",
                        lvl1Id: "1000005",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.RightLeg.HipOrThighBoneOrThigh,
                        value: "aboutBodilyInjury.rightLeg.hipOrThighBoneOrThigh",
                        lvl1Id: "1000005",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.RightLeg.KneeOrKnees,
                        value: "aboutBodilyInjury.rightLeg.knees",
                        lvl1Id: "1000005",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.RightLeg.ToeOrToes,
                        value: "aboutBodilyInjury.rightLeg.toes",
                        lvl1Id: "1000005",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.RightLeg.Leg,
                        value: "aboutBodilyInjury.rightLeg.leg",
                        lvl1Id: "1000005",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.RightLeg.SeveralOptionsAbove,
                        value: "aboutBodilyInjury.rightLeg.several",
                        lvl1Id: "1000005",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.LeftLeg.FootOrFeet,
                        value: "aboutBodilyInjury.leftLeg.footFeet",
                        lvl1Id: "1000006",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.LeftLeg.Ankle,
                        value: "aboutBodilyInjury.leftLeg.ankle",
                        lvl1Id: "1000006",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.LeftLeg.HeelOrAchillesTendon,
                        value: "aboutBodilyInjury.leftLeg.heelAchilesTendon",
                        lvl1Id: "1000006",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.LeftLeg.HipOrThighBoneOrThigh,
                        value: "aboutBodilyInjury.leftLeg.hipOrThighBoneOrThigh",
                        lvl1Id: "1000006",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.LeftLeg.KneeOrKnees,
                        value: "aboutBodilyInjury.leftLeg.knees",
                        lvl1Id: "1000006",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.LeftLeg.ToeOrToes,
                        value: "aboutBodilyInjury.leftLeg.toes",
                        lvl1Id: "1000006",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.LeftLeg.Leg,
                        value: "aboutBodilyInjury.leftLeg.leg",
                        lvl1Id: "1000006",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.LeftLeg.SeveralOptionsAbove,
                        value: "aboutBodilyInjury.leftLeg.several",
                        lvl1Id: "1000006",
                        lvl2Id: ""
                    },
                    {
                        id: Constants.BodyParts.Parts.HeadAndNeck.HeadOrFacePart.Scull,
                        value: "aboutBodilyInjury.faceOrHead.scull",
                        lvl1Id: "1000001",
                        lvl2Id: "1000007"
                    },
                    {
                        id: Constants.BodyParts.Parts.HeadAndNeck.HeadOrFacePart.CheekOrZygomaticBone,
                        value: "aboutBodilyInjury.faceOrHead.cheekOrZygomaticBone",
                        lvl1Id: "1000001",
                        lvl2Id: "1000007"
                    },
                    {
                        id: Constants.BodyParts.Parts.HeadAndNeck.HeadOrFacePart.JawOrChin,
                        value: "aboutBodilyInjury.faceOrHead.jawChin",
                        lvl1Id: "1000001",
                        lvl2Id: "1000007"
                    },
                    {
                        id: Constants.BodyParts.Parts.HeadAndNeck.HeadOrFacePart.MouthOrLip,
                        value: "aboutBodilyInjury.faceOrHead.mouthLip",
                        lvl1Id: "1000001",
                        lvl2Id: "1000007"
                    },
                    {
                        id: Constants.BodyParts.Parts.HeadAndNeck.HeadOrFacePart.Nose,
                        value: "aboutBodilyInjury.faceOrHead.nose",
                        lvl1Id: "1000001",
                        lvl2Id: "1000007"
                    },
                    {
                        id: Constants.BodyParts.Parts.HeadAndNeck.HeadOrFacePart.Forehead,
                        value: "aboutBodilyInjury.faceOrHead.forehead",
                        lvl1Id: "1000001",
                        lvl2Id: "1000007"
                    },
                    {
                        id: Constants.BodyParts.Parts.HeadAndNeck.HeadOrFacePart.HeadOrBrain,
                        value: "aboutBodilyInjury.faceOrHead.headBrain",
                        lvl1Id: "1000001",
                        lvl2Id: "1000007"
                    },
                    {
                        id: Constants.BodyParts.Parts.HeadAndNeck.HeadOrFacePart.EyeOrEyes,
                        value: "aboutBodilyInjury.faceOrHead.eyes",
                        lvl1Id: "1000001",
                        lvl2Id: "1000007"
                    },
                    {
                        id: Constants.BodyParts.Parts.HeadAndNeck.HeadOrFacePart.Eyebrows,
                        value: "aboutBodilyInjury.faceOrHead.eyebrows",
                        lvl1Id: "1000001",
                        lvl2Id: "1000007"
                    },
                    {
                        id: Constants.BodyParts.Parts.HeadAndNeck.HeadOrFacePart.EarOrEars,
                        value: "aboutBodilyInjury.faceOrHead.ears",
                        lvl1Id: "1000001",
                        lvl2Id: "1000007"
                    },
                    {
                        id: Constants.BodyParts.Parts.HeadAndNeck.HeadOrFacePart.SeveralOptionsAbove,
                        value: "aboutBodilyInjury.faceOrHead.several",
                        lvl1Id: "1000001",
                        lvl2Id: "1000007"
                    },
                    {
                        id: Constants.BodyParts.Parts.RightLeg.KneePart.Patella,
                        value: "aboutBodilyInjury.rightLeg.kneePart.patella",
                        lvl1Id: "1000005",
                        lvl2Id: "1000042"
                    },
                    {
                        id: Constants.BodyParts.Parts.RightLeg.KneePart.Parcel,
                        value: "aboutBodilyInjury.rightLeg.kneePart.parcel",
                        lvl1Id: "1000005",
                        lvl2Id: "1000042"
                    },
                    {
                        id: Constants.BodyParts.Parts.RightLeg.KneePart.Ligament,
                        value: "aboutBodilyInjury.rightLeg.kneePart.ligament",
                        lvl1Id: "1000005",
                        lvl2Id: "1000042"
                    },
                    {
                        id: Constants.BodyParts.Parts.RightLeg.KneePart.Meniscus,
                        value: "aboutBodilyInjury.rightLeg.kneePart.meniscus",
                        lvl1Id: "1000005",
                        lvl2Id: "1000042"
                    },
                    {
                        id: Constants.BodyParts.Parts.RightLeg.KneePart.SeveralOptionsAbove,
                        value: "aboutBodilyInjury.rightLeg.kneePart.several",
                        lvl1Id: "1000005",
                        lvl2Id: "1000042"
                    },
                    {
                        id: Constants.BodyParts.Parts.LeftLeg.KneePart.Patella,
                        value: "aboutBodilyInjury.leftLeg.kneePart.patella",
                        lvl1Id: "1000006",
                        lvl2Id: "1000050"
                    },
                    {
                        id: Constants.BodyParts.Parts.LeftLeg.KneePart.Parcel,
                        value: "aboutBodilyInjury.leftLeg.kneePart.parcel",
                        lvl1Id: "1000006",
                        lvl2Id: "1000050"
                    },
                    {
                        id: Constants.BodyParts.Parts.LeftLeg.KneePart.Ligament,
                        value: "aboutBodilyInjury.leftLeg.kneePart.ligament",
                        lvl1Id: "1000006",
                        lvl2Id: "1000050"
                    },
                    {
                        id: Constants.BodyParts.Parts.LeftLeg.KneePart.Meniscus,
                        value: "aboutBodilyInjury.leftLeg.kneePart.meniscus",
                        lvl1Id: "1000006",
                        lvl2Id: "1000050"
                    },
                    {
                        id: Constants.BodyParts.Parts.LeftLeg.KneePart.SeveralOptionsAbove,
                        value: "aboutBodilyInjury.leftLeg.kneePart.several",
                        lvl1Id: "1000006",
                        lvl2Id: "1000050"
                    }
                ]
            );
        }

        if (identifier === Constants.ClassifierIdentifier.InjuryType) {
            return Promise.resolve([
                    {
                        id: Constants.BodyParts.Kind.Rupture,
                        value: "aboutBodilyInjury.bodilyInjuryKind.rupture"
                    },
                    {
                        id: Constants.BodyParts.Kind.ContusionDamage,
                        value: "aboutBodilyInjury.bodilyInjuryKind.contusion"
                    },
                    {
                        id: Constants.BodyParts.Kind.InsectBiteInfection,
                        value: "aboutBodilyInjury.bodilyInjuryKind.insectBite"
                    },
                    {
                        id: Constants.BodyParts.Kind.CrackOrFracture,
                        value: "aboutBodilyInjury.bodilyInjuryKind.crackFacture"
                    },
                    {
                        id: Constants.BodyParts.Kind.Spraining,
                        value: "aboutBodilyInjury.bodilyInjuryKind.spraining"
                    },
                    {
                        id: Constants.BodyParts.Kind.WoundingOrBleeding,
                        value: "aboutBodilyInjury.bodilyInjuryKind.woundingBleeding"
                    },
                    {
                        id: Constants.BodyParts.Kind.Luxation,
                        value: "aboutBodilyInjury.bodilyInjuryKind.luxation"
                    },
                    {
                        id: Constants.BodyParts.Kind.OtherOrMultiple,
                        value: "aboutBodilyInjury.bodilyInjuryKind.other"
                    }
                ]
            );
        }

        if (identifier === Constants.ClassifierIdentifier.InjuryTreatmentType) {
            return Promise.resolve([
                    {
                        id: Constants.BodyParts.TreatmentType.GypsumOrSling,
                        value: "aboutBodilyInjury.bodilyInjuryTreatment.gypsumSling"
                    },
                    {
                        id: Constants.BodyParts.TreatmentType.GypsumOrOrthosis,
                        value: "aboutBodilyInjury.bodilyInjuryTreatment.gypsumOrthosis"
                    },
                    {
                        id: Constants.BodyParts.TreatmentType.Crutches,
                        value: "aboutBodilyInjury.bodilyInjuryTreatment.crutches"
                    },
                    {
                        id: Constants.BodyParts.TreatmentType.Penicillin,
                        value: "aboutBodilyInjury.bodilyInjuryTreatment.penicillin"
                    },
                    {
                        id: Constants.BodyParts.TreatmentType.Stitches,
                        value: "aboutBodilyInjury.bodilyInjuryTreatment.stitches"
                    },
                    {
                        id: Constants.BodyParts.TreatmentType.TapeOrGlue,
                        value: "aboutBodilyInjury.bodilyInjuryTreatment.tapeOrGlue"
                    },
                    {
                        id: Constants.BodyParts.TreatmentType.Rescheduling,
                        value: "aboutBodilyInjury.bodilyInjuryTreatment.rescheduling"
                    },
                    {
                        id: Constants.BodyParts.TreatmentType.SeveralOptionsAbove,
                        value: "aboutBodilyInjury.bodilyInjuryTreatment.several"
                    }
                ]
            );
        }

        if (identifier === Constants.ClassifierIdentifier.MedicalSpecialistType) {
            return Promise.resolve([
                    {
                        id: Constants.MedicalSpecialistIdentifier.Physiotherapist,
                        value: "aboutBodilyInjury.medicalSpecialistType.physiotherapist"
                    },
                    {
                        id: Constants.MedicalSpecialistIdentifier.Doctor,
                        value: "aboutBodilyInjury.medicalSpecialistType.doctor"
                    },
                    {
                        id: Constants.MedicalSpecialistIdentifier.Psychologist,
                        value: "aboutBodilyInjury.medicalSpecialistType.psychologist"
                    },
                    {
                        id: Constants.MedicalSpecialistIdentifier.Nurse,
                        value: "aboutBodilyInjury.medicalSpecialistType.nurse"
                    },
                    {
                        id: Constants.MedicalSpecialistIdentifier.Other,
                        value: "aboutBodilyInjury.medicalSpecialistType.other"
                    }
                ]
            );
        }

        if (identifier === Constants.ClassifierIdentifier.AccidentWorkRelatedType) {
            return Promise.resolve([
                    {
                        id: Constants.InjuryAppearedPlace.AtWork,
                        value: "otherInformation.injuryAppearedPlace.atWork"
                    },
                    {
                        id: Constants.InjuryAppearedPlace.ToFromWork,
                        value: "otherInformation.injuryAppearedPlace.toOrFromWork"
                    },
                ]
            );
        }

        if (identifier === Constants.ClassifierIdentifier.ToothType) {
            return Promise.resolve([
                    {
                        id: Constants.Tooth.Type.Milk,
                        value: "aboutDentalInjury.dentalInjuryDescription.tooth.type.milk"
                    },
                    {
                        id: Constants.Tooth.Type.Denture,
                        value: "aboutDentalInjury.dentalInjuryDescription.tooth.type.denture"
                    },
                    {
                        id: Constants.Tooth.Type.Permanent,
                        value: "aboutDentalInjury.dentalInjuryDescription.tooth.type.permanent"
                    },
                ]
            );
        }

        if (identifier === Constants.ClassifierIdentifier.DentalDamageType) {
            return Promise.resolve([
                    {
                        id: Constants.Tooth.DentalDamageOrDiagnosis.PartlyBroken,
                        value: "aboutDentalInjury.dentalInjuryDescription.tooth.diagnosis.partlyBroken"
                    },
                    {
                        id: Constants.Tooth.DentalDamageOrDiagnosis.CompletelyBroken,
                        value: "aboutDentalInjury.dentalInjuryDescription.tooth.diagnosis.completelyBroken"
                    },
                    {
                        id: Constants.Tooth.DentalDamageOrDiagnosis.Movable,
                        value: "aboutDentalInjury.dentalInjuryDescription.tooth.diagnosis.movable"
                    },
                    {
                        id: Constants.Tooth.DentalDamageOrDiagnosis.CrackOrFracture,
                        value: "aboutDentalInjury.dentalInjuryDescription.tooth.diagnosis.crackOrFracture"
                    },
                    {
                        id: Constants.Tooth.DentalDamageOrDiagnosis.ChewingAndBiteDamage,
                        value: "aboutDentalInjury.dentalInjuryDescription.tooth.diagnosis.chewingAndBiteDamage"
                    },
                    {
                        id: Constants.Tooth.DentalDamageOrDiagnosis.Other,
                        value: "aboutDentalInjury.dentalInjuryDescription.tooth.diagnosis.otherDentalInjury",
                    },
                ]
            );
        }

        if (identifier === Constants.ClassifierIdentifier.AdditionalBuildingType) {
            return Promise.resolve([
                    {
                        id: Constants.Home.AdditionalBuildingType.AttefallHouse,
                        value: "product.homeBuilding.aboutYouAndYourHome.otherBuildings.attefallHouse"
                    },
                    {
                        id: Constants.Home.AdditionalBuildingType.Sauna,
                        value: "product.homeBuilding.aboutYouAndYourHome.otherBuildings.sauna"
                    },
                    {
                        id: Constants.Home.AdditionalBuildingType.AnimalStable,
                        value: "product.homeBuilding.aboutYouAndYourHome.otherBuildings.animalStable"
                    },
                    {
                        id: Constants.Home.AdditionalBuildingType.Garage,
                        value: "product.homeBuilding.aboutYouAndYourHome.otherBuildings.garage"
                    },
                    {
                        id: Constants.Home.AdditionalBuildingType.GuestCottage,
                        value: "product.homeBuilding.aboutYouAndYourHome.otherBuildings.guestCottage"
                    },
                    {
                        id: Constants.Home.AdditionalBuildingType.GlassHouse,
                        value: "product.homeBuilding.aboutYouAndYourHome.otherBuildings.glassHouse"
                    },
                    {
                        id: Constants.Home.AdditionalBuildingType.Barn,
                        value: "product.homeBuilding.aboutYouAndYourHome.otherBuildings.barn"
                    },
                    {
                        id: Constants.Home.AdditionalBuildingType.OtherBuildingType,
                        value: "product.homeBuilding.aboutYouAndYourHome.otherBuildings.otherBuildingType"
                    },
                ]
            );
        }

        if (identifier === Constants.ClassifierIdentifier.ClaimPetCostType) {
            return Promise.resolve([
                {
                    id: Constants.ClaimPet.CostType.Vet,
                    value: "claim.pet.costType.vet"
                },
                {
                    id: Constants.ClaimPet.CostType.Medicine,
                    value: "claim.pet.costType.medicine"
                }
            ]);
        }

        if (identifier === Constants.ClassifierIdentifier.ClaimPetHealthType) {
            return Promise.resolve([
                {
                    id: Constants.ClaimPet.HealthType.Healthy,
                    value: "claim.pet.healthType.healthy"
                },
                {
                    id: Constants.ClaimPet.HealthType.NotHealthy,
                    value: "claim.pet.healthType.notHealthy"
                },
                {
                    id: Constants.ClaimPet.HealthType.Deceased,
                    value: "claim.pet.healthType.deceased"
                }
            ]);
        }

        return this.getReferenceTableValues(
            identifier,
            locale,
            this.getCustomFields(identifier)
        );
    }

    private getCustomFields(identifier: string | number): string[] | undefined {
        switch (identifier) {
            case Constants.ClassifierIdentifier.BodyParts:
                return [
                    Constants.ClassifierCustomField.Lvl1Id,
                    Constants.ClassifierCustomField.Lvl2Id,
                ];
            default:
                return undefined;
        }
    }

    private async getTableValues(endpoint: Endpoint, params: {
        [key: string]: string | number
    }, locale: string, customFields?: string[]): Promise<IClassifier[]> {
        const data = await super.execute<any, ReferenceTable>(
            endpoint,
            {
                ...params,
                locale,
                customFields: customFields ? customFields.join(",") : undefined
            }
        );

        return data.values;
    }

    private async getReferenceTableValues(identifier: string | number, locale: string, customFields?: string[]): Promise<IClassifier[]> {
        const data = await super.execute<any, ReferenceTable>(
            Workflow.Classifiers.ReferenceTable,
            {
                identifier,
                locale,
                customFields: customFields ? customFields.join(",") : undefined
            }
        );

        return data.values;
    }

    private sortValuesAlphabetically(values: IClassifier[]): IClassifier[] {
        values = values.sort((value1, value2) => {
            return value1.value.localeCompare(value2.value);
        });

        return values;
    }

    private sortValuesByClassifierFilterOrder(values: IClassifier[], filterValues: TFilterValue[]): IClassifier[] {
        values = values.sort((value1, value2) => {
            return filterValues.indexOf(value1.id) - filterValues.indexOf(value2.id);
        });

        return values;
    }
}
